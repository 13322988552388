import { MASTERCHEF_ABI } from "../config/Masterchef";
import { GAMEADDRESS_ABI } from "../config/Gameabi"
import { ERC20_ABI } from "../config/erc20";
import toast, { Toaster } from 'react-hot-toast';
import { GetChainIndex, UseWeb3, useWeb3, usehttpProvider } from "./useWeb3"
import { CHAINS } from "../config/env";
import BigNumber from 'bignumber.js';
import { GAMECHAINS } from "../config/gameenv";
import { iconTheme, position, style } from "./useToast";
import Big from 'big.js';
const bigInt = require('big-integer');
// masterchef --> cilent reject the stake concept ,  So the masterchefaddress useless.
export const UseMasterChef = async () => {
    try {
        const web3 = await useWeb3();
        console.log("web------",web3);
        const contract = new web3.eth.Contract(MASTERCHEF_ABI, getMasterChefAddress());
console.log("contract_contract",contract);
        return contract;
    } catch (e) {

    }
}

export const UseGameAddress = async () => {
    try {
        const web3 = await useWeb3();
        const contract = new web3.eth.Contract(GAMEADDRESS_ABI, getGameAddress());
        return contract;
    } catch (e) {

    }
}

export const UsegetGasPrice = async () => {
    try {
        const web3 = await useWeb3();
        let gasPrice = await web3.eth.getGasPrice();
        return gasPrice;

    } catch (e) {
        console.log("UsegetGasPrice_err", e)
    }
}



export const UsehttpMasterChef = async () => {
    try {
        // console.log("log");
        const web3 = await usehttpProvider();
        const contract = new web3.eth.Contract(MASTERCHEF_ABI, getMasterChefAddress());
        console.log("contract_contractcontractcontract", contract);

        return contract;
    } catch (e) {

    }
}

export const UseERC20 = async (VALUE) => {
    try {
        const web3 = await useWeb3();
        // const web3 = await usehttpProvider();
        const Tokencontract = new web3.eth.Contract(ERC20_ABI, VALUE);
        return Tokencontract;
    } catch (e) {

    }
}

export const UseHttpERC20 = async (VALUE) => {
    try {
        const web3 = await usehttpProvider();
        const Tokencontract = new web3.eth.Contract(ERC20_ABI, VALUE);
        return Tokencontract;
    } catch (e) {

    }
}

export const UseUserBalance = async (TOKEN, account) => {
    try {
        const erc20 = await UseHttpERC20(TOKEN);
        const balance = await erc20.methods.balanceOf(account).call();
        return balance;
    } catch (e) {

    }
}


// export const GetContractBalance =  async() =>{
//     try{

//         const walletadddress = "0x6c2482496B17adaCA4673E36670B0478E33AEAAC";
//         const Token = "0xEB1829Dc3C87E3a745804018B0F595B581aFE52a";
//         const gameContract = "0x797208Fa45Bf7381462ca61a12d129870b38425D";
//         const erc20 = await UseERC20(Token);
   
//         const balance = await erc20.methods.allowance(walletadddress,gameContract).call()
       
      
//         console.log("GetContractBalance",balance);


//     }catch(e){
// console.log("GetContractBalance_err", e)
//     }
// }


export const getStakedBalance = async (account) => {

    // const stake = await UseMasterChef();
    const stake = await UsehttpMasterChef()


}

export const sleep = (ms) => {
    new Promise(resolve => setTimeout(resolve, ms))
}


export const getNativeToken = () => {
    const index = GetChainIndex();
    console.log("index", index);
    return CHAINS[index].NATIVE;
}

// export const getMulticallAddress = ()=>{
//     const index = GetChainIndex();
//     return CHAINS[index].MULTICALL;
// }

export const getMasterChefAddress = () => {
    try {
        const index = GetChainIndex();
        const ContractAddress = CHAINS[index].MASTERCHEF;
        console.log("ContractAddress_ContractAddress", ContractAddress);

        return ContractAddress;
    } catch (e) {

    }
}

export const getGameAddress = () => {
    try {
        const index = GetChainIndex();
        const ContractAddress = GAMECHAINS[index].GAMEADDRESS;
        console.log("ContractAddress_ContractAddress", ContractAddress);

        return ContractAddress;
    } catch (e) {

    }
}



export const checkIsApproved = async (account, token) => {
    // console.log("checkIsApproved",account,token)
    try {
        const erc20contract = await UseHttpERC20(token);
        const allow = await erc20contract.methods.allowance(account, getMasterChefAddress()).call();
        console.log("allow_data", allow)
        return (parseInt(allow) > 0) ? true : false;
    } catch (e) {

    }
}


export const checkIsApprovedGame = async (account, token) => {
    try {
        const erc20contract = await UseHttpERC20(token);
        const allow = await erc20contract.methods.allowance(account, getGameAddress()).call();
        console.log("allow_data", allow)
        return {
            allowstatus: (parseInt(allow) > 0) ? true : false,
            allowamount: (parseInt(allow))
        }
    } catch (e) {

    }
}

export const approveGame = async (account, token) => {
    console.log('accout------', account);
    console.log("token-------", token);
    try {
        const gasPrice = await UsegetGasPrice();
        let estimatedGasLimit_data = await UsegetApproveEstimateGasLimit(getGameAddress(),account, token)
        const erc20Contract = await UseERC20(token);
        const data =   erc20Contract.methods.approve(getGameAddress(), "115792089237316195423570985008687907853269984665640564039457584007913129639935").send({ from: account, gas : estimatedGasLimit_data, gasPrice, value : "0" });
        console.log("approve_dasta", data)
        await toast.promise(data, {
            loading: 'Approving...',
            success: 'Approved Successfully',
            error: 'Try Again',
        }, {
            position : position.position,
            style : style,
            iconTheme : iconTheme
        })
            return data;
    } catch (error) {
        console.log("approveGameerr--", error);
    }

}

export const approveContract = async (account, token) => {
    console.log('accout------', account);
    console.log("token-------", token);
    try {
        const gasPrice = await UsegetGasPrice();
        let estimatedGasLimit_data = await UsegetApproveEstimateGasLimit(getMasterChefAddress(),account, token)
        const erc20Contract = await UseERC20(token);
        const data = erc20Contract.methods.approve(getMasterChefAddress(), "115792089237316195423570985008687907853269984665640564039457584007913129639935").send({ from: account, gas : estimatedGasLimit_data, gasPrice, value : "0" });
        console.log("approve_dasta", data)
        await toast.promise(data, {
            loading: 'Approving...',
            success: 'Approved Successfully',
            error: 'Try Again',
        }, {
            style: {
                minWidth: '300px',
                minHeight: '55px'
            }
        })
    } catch (error) {
        console.log("err--", error);
    }

}


export const UseTokenInfo = async (TOKEN) => {
    try {
        const token = await UseHttpERC20(TOKEN);
        const name = await token.methods.name().call();
        const symbol = await token.methods.symbol().call();
        const decimals = await token.methods.decimals().call();
        console.log("UseTokenInfo_UseTokenInfo", name)
        const data = {
            name: name,
            symbol: symbol,
            decimals: decimals,
            address: TOKEN
        }
        console.log("data_data_Vdatav", data);
        return data;
    } catch (e) {
        console.log('UseTokenInfo', e)
    }
}




export const getUserBalance = async (token, account) => {

    try {
        console.log("111 token, account token_Balance", token, account)
        const tokenContract = await UseHttpERC20(token);
        const tokenInfo = await UseTokenInfo(token);
        console.log("222 token, account token_Balance", token, account)
        const balance = await tokenContract.methods.balanceOf(account).call();      
        const balanceTokens = ((new BigNumber(balance)).div(new BigNumber(10).pow(tokenInfo.decimals))).toNumber();
        console.log("token, account token_Balance", token, account, balance, balanceTokens)
        return balanceTokens.toString();
    } catch (e) {
console.log('getUserBalancegetUserBalance_Err', e)
    }
}

export const toFixedNumber = (x) => {
    try {
        console.log("xtoFixedNumberdata asdfasdfasdfasdfawrwe", x, Math.abs(x) < 1.0);
        if (Math.abs(x) < 1.0) {
            console.log('asdfasdfasdfasdfawrwe', x, x.toString().split('e-')[1])
            var e = parseInt(x.toString().split('e-')[1]);
            console.log("xtoFixedNumberdata_e", e)
            if (e) {
                x *= Math.pow(10, e - 1);
                x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
            }
        } else {
            var e = parseInt(x.toString().split('+')[1]);
            console.log("e_false", x, e)
            if (e > 20) {
                e -= 20;
                console.log("e values", e)
                x /= Math.pow(10, e);
                console.log("e values", x /= Math.pow(10, e))
                x += (new Array(e + 1)).join('0');
                console.log('evalues 111', x += (new Array(e + 1)).join('0'));
            }
        }
        return x; 
    } catch (e) {
        console.log("toFixedNumber_err", e)
    }

}


export const  convertScientificToWholeNumber = (number) => {
    try {
        console.log("numberaaaa",number);
        // Create a Big instance with the scientific notation number
        let bigNumber = new Big(number);
    
        // Convert it to a string and remove any non-numeric characters
        let wholeNumber = bigNumber.toFixed().replace(/[^\d.-]/g, '');
    
        // Convert the string to a BigInt
        return bigInt(wholeNumber).toString();
    } catch (e) {
        console.log("convertScientificToWholeNumber_err", e)
    }

}

// // Test the function with the example number
// let result = convertScientificToWholeNumber(1.157920892373162e+77);
// console.log("aaaaaaaaaaaaaaaaaa",result);

// // Test the function with the example number
// let result = convertScientificToWholeNumber(1.157920892373162e+77);
// console.log(result);


export const searchedValue = async (tokenList, searchSymbol) => {
    // console.log("searchedValue",tokenList,searchSymbol)
    const filteredData = await tokenList.filter(value => ((value.lockedPeriod)).includes(searchSymbol));
    return filteredData;
}


export const UsegetApproveEstimateGasLimit = async (contractAddress,account, token) => {
    try {

        const web3 = await useWeb3();
        const erc20Contract = await UseERC20(token);
        console.log("erc20Contract_V_erc20Contract", erc20Contract.methods);
      //  let contractAddress = await getMasterChefAddress();
        console.log("UsegetEstimateGasLimit_paramsdata", contractAddress, account, token)
        const dataC = await erc20Contract.methods.approve(contractAddress, "115792089237316195423570985008687907853269984665640564039457584007913129639935").encodeABI();
        console.log("dataC_Data", dataC)
        const estimatedGasLimit = await web3.eth.estimateGas({
            from: account,
            to: contractAddress,
            data: dataC,
        });
        console.log("estimatedGasLimit_approve", estimatedGasLimit)
        return estimatedGasLimit;
    } catch (err) {
        console.log("UsegetEstimateGas_err", err)
    }
}

export const UsegetDepositEstimateGasLimit = async (pid, convertedAmount, account) => {
    try {

        const web3 = await useWeb3();
        const stakingContract = await UseMasterChef();
        let contractAddress = await getMasterChefAddress();
        console.log("pid,convertedAmount,account", pid, convertedAmount, account)
        const dataC = await stakingContract.methods.deposit(pid, convertedAmount, account).encodeABI();
        console.log("UsegetEstimateGasLimit_paramsdata", contractAddress, account, dataC, web3.eth)
        const estimatedGasLimit = await web3.eth.estimateGas({
            from: account,
            to: contractAddress,
            data: dataC,
        });
        console.log("estimatedGasLimit_Deposit", estimatedGasLimit)
        return estimatedGasLimit;
    } catch (err) {
        console.log("UsegetEstimateGas_err_deposit", err)
    }
}


export const UsegetBetEstimateGasLimit = async (_amount, _token, _prediction, _multiplier, _account) => {
    console.log("_amount, _token, _prediction, _multiplier, _account mmm", _amount, _token, _prediction, _multiplier, _account)
    try {

        const web3 = await useWeb3();
        const stakingContract = await UseGameAddress();
        //getGameAddress
        let contractAddress = await getGameAddress();
        console.log("UsegetBetEstimateGasLimit_contactAddress", stakingContract)
        const dataC = await stakingContract.methods.bet(_amount, _token, _prediction, _multiplier).encodeABI();
        console.log("dataC_dataC", dataC, _account, contractAddress)
        const estimatedGasLimit = await web3.eth.estimateGas({
            from: _account,
            to: contractAddress,
            data: dataC,
        });
        console.log("estimatedGasLimit_Deposit", estimatedGasLimit);
        if (estimatedGasLimit != undefined) {
            return {status : true, data : estimatedGasLimit};
        }


    } catch (e) {
        console.log("UsegetBetEstimateGasLimit_err", e);
        return { status : false, error : e};
    }
}


export const UseUserBet = async (_amount, _token, _prediction, _multiplier, _account) => {

    try{
        console.log("enter this useuserbet 222", _amount, _token, _prediction, _multiplier)
        const gasPrice = await UsegetGasPrice();
        const stakingContract = await UseGameAddress();
        // 853800
        let estimatedGasLimit_data = await UsegetBetEstimateGasLimit(_amount, _token, _prediction, _multiplier, _account);
        console.log("estimatedGasLimit_data", estimatedGasLimit_data,gasPrice);
        if(estimatedGasLimit_data?.status){
            console.log('useuserbet data', _amount, _token, _prediction, _multiplier)
            const betdata = await stakingContract.methods.bet(_amount, _token, _prediction, _multiplier).send({ from : _account, gasPrice: gasPrice , value : "0"});
            console.log("UseUserBet_Data", betdata);
            console.log("UseUserBet_Data", betdata);
            return betdata;
        } else {
            return "something wrong !!!"
        }
    } catch (e) {
        console.log("UseUserBet_err", e,e?.message?.split(':')[1],e?.message?.split(':')[1]?.includes('User denied transaction signature'))
        if(e?.message?.split(':')[1]?.includes('User denied transaction signature')){
            return "rejected"
        }
    }
}


export const UseUserWithdraw = async (_amount, _token, _account) => {
    try {
        const stakingContract = await UseGameAddress();

        console.log("_amount, _token, _account liki", _amount.toString(), _token, _account)
        const withdraw =  stakingContract.methods.withdraw(_amount.toString(), _token).send({ from : _account});
        console.log('withdrawdata liki', withdraw);
        await toast.promise(withdraw, {
            loading: 'withdraw...',
            success: 'withdrew Successfully',
            error: 'Try Again',
        }, {
            position : position.position,
            style : style,
            iconTheme : iconTheme
        })
            return withdraw;

    } catch (e) {
        console.log("UseWithdraw_err", e)
    }
}


export const UseRefferalClaim = async (toFixedAmount, WINRToken, accountInfo) => {
    try {
        console.log('toFixedamountdata', toFixedAmount, WINRToken, accountInfo, toFixedAmount.toString())
        const web3 = await UseWeb3();
        const stakingContract = await UseGameAddress();
        console.log("stakingContract_stakingContract". stakingContract)
        //Testnet
        let salt = "XYZ1812e317PIGGIEMATICZ1298ey189e313126231"
        // Mainnet
        // let salt = "YSAABARKJEAJ57GHD4RENWCPMN3P55M9I3YSAABARKJEAJ57GHD4RENWCPMN3P55M9I3"
        const valueold = web3.utils.encodePacked(salt, toFixedAmount?.toString()); 
        console.log("valueolddata". valueold)
        const signature =  web3.utils.keccak256(valueold);
        console.log('checkthis withdraw amount', toFixedAmount.toString(),WINRToken,signature.toString())
        let Claimdata = await stakingContract.methods.getClaimables(toFixedAmount.toString(),WINRToken,signature.toString()).call({ from : accountInfo })
        console.log("UseRefferalClaim", (Claimdata));
        let claimHex =  stakingContract.methods.claim(Claimdata).send({ from : accountInfo});
        console.log("stakingcontract", signature, claimHex)
        await toast.promise(claimHex, {
            loading: 'withdraw...',
        success: 'withdrew Successfully',
        error: 'Try Again',
        }, {
            position : position.position,
            style : style,
            iconTheme : iconTheme
        })
        return claimHex

    } catch (e) {
        console.log("UseRefferalClaim_err", e)
    }
}

