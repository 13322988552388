export const ONEDAYINSECONDS = 86400;

export const BURN = "0x000000000000000000000000000000000000dEaD";

export const BLOCKS_PER_YEAR = 10512000;

export const CHAINS = [
// masterchef --> cilent reject the stake concept ,  So the masterchefaddress useless.

  // {
  //   NAME: "BSC-MAINNET",
  //   // https://data-seed-prebsc-1-s1.binance.org:8545/
  //   // RPC_URL: "https://data-seed-prebsc-2-s1.bnbchain.org:8545", // BSC TESTNET
  //   RPC_URL: "https://bsc-dataseed1.binance.org/",
  //   CHAIN_ID: 56,
  //   ROUTER: "0x1F0929D79d2e992C31dD70A637C33e4a8440d3b0",
  //   TOKENADDRESS: "0x3F1c44C8b7e672674Ee9faE3B96cB3B14F034d72", // DITMAX Token
  //   // MASTERCHEF: "0xA1Ad9d32096693f66091e02c7155E09E84F8f70f",
  //   MASTERCHEF: "0xc9693F997d0De9cb576151CE4741Ca6af66cb882",
  //   // MASTERCHEF: "0x797208Fa45Bf7381462ca61a12d129870b38425D",
  //   NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B",
  //   BLOCK_EXPLORER_URL : "https://testnet.bscscan.com/",
  //   CHAIN_CURRENCY : "tBNB",
  //   PROJECT_ID : "b8a1daa2dd22335a2fe1d2e139980ae0"
  // },
  // {
  //   NAME: "Fuji",
  //   // https://data-seed-prebsc-1-s1.binance.org:8545/
  //   RPC_URL: "https://api.avax-test.network/ext/bc/C/rpc", // FUJI TESTNET
  //   CHAIN_ID: 43113,
  //   ROUTER: "0x1F0929D79d2e992C31dD70A637C33e4a8440d3b0",
  //   TOKENADDRESS: "0x3F1c44C8b7e672674Ee9faE3B96cB3B14F034d72", // DITMAX Token
  //   // MASTERCHEF: "0xA1Ad9d32096693f66091e02c7155E09E84F8f70f",
  //   MASTERCHEF: "0xc9693F997d0De9cb576151CE4741Ca6af66cb882",
  //   // MASTERCHEF: "0x797208Fa45Bf7381462ca61a12d129870b38425D",
  //   NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
  // },


  {
    NAME: "BSC-TEST",
    RPC_URL: "https://bsc-testnet.publicnode.com", // BSC TESTNET
    CHAIN_ID: 97,
    ROUTER: "0x1F0929D79d2e992C31dD70A637C33e4a8440d3b0",
    TOKENADDRESS: "0x3F1c44C8b7e672674Ee9faE3B96cB3B14F034d72", // DITMAX Token
    // MASTERCHEF: "0xA1Ad9d32096693f66091e02c7155E09E84F8f70f",
    MASTERCHEF: "0x0317480d393a5d6eFdBc3898fA7977B90701F5CF",
    NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
  },
  // {
  //   NAME: "AVALANCHE",
  //   RPC_URL: "https://api.avax-test.network/ext/bc/C/rpc", // ETH TESTNET
  //   CHAIN_ID: 43113,
  //   ROUTER: "0x1F0929D79d2e992C31dD70A637C33e4a8440d3b0",
  //   TOKENADDRESS: "0x3F1c44C8b7e672674Ee9faE3B96cB3B14F034d72", // DITMAX Token
  //   MASTERCHEF: "0xA1Ad9d32096693f66091e02c7155E09E84F8f70f",

  //   NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
  // },
  //   {
  //     NAME: "Polygon-TEST",
  //     RPC_URL:"https://matic-mumbai.chainstacklabs.com",
  //     CHAIN_ID:80001, 
  //     TOKENADDRESS: "0x9433f3BaAE2984C6F5C8DE6989aB6dee23eaEE17",
  //      MASTERCHEF: "0x74962D8c90dCb1e4761DF94115BE449044179e5A", // new 
  //     // MASTERCHEF: "0x1afd408a544e901af55cB8735d8c6B49c47A11b2",//old
  //     NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
  // },
  //   {
  //     NAME: "BSC-MAINNET",
  //     RPC_URL:"https://bsc-dataseed1.binance.org/",
  //     CHAIN_ID:56,
  //     TOKENADDRESS: "0x9eeB6c5ff183E6001c65a12D70026b900AE76781",
  //     MASTERCHEF: "0x825A21c5A32d22b1241C7672EC9D04025960f766", 
  //     NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
  // },
  // {
  //   NAME: "BSC-MAINNET",
  //   RPC_URL:"https://bsc-dataseed1.binance.org/",
  //   CHAIN_ID:56,
  //   TOKENADDRESS: "0x9eeB6c5ff183E6001c65a12D70026b900AE76781", //irea
  //   MASTERCHEF: "0x5fb7846a3fD122De5feb59bb1Ef67F742a6804d8", 
  //   NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B",
  //   USDT:"0x55d398326f99059ff775485246999027b3197955",
  //   USDI: "0x91c7eBbDcbdE50559FDA424dd7Dc9AF142B9Ed58"
  // },

];



// Local
// export const FRONTENDURL = "http://localhost:3000/";
// export const BACKURL = "http://localhost:7065";

//Tesnet
// export const FRONTENDURL = "https://pigibet.maticz.in/"
// export const BACKURL = "https://backend-pigibet.maticz.in" 


//Mainnet
// export const FRONTENDURL = "https://app.pigi.bet/"
// export const BACKURL = "https://api.pigi.bet"


// honeyblue Testnet
export const FRONTENDURL = "http://localhost:3000/"
export const BACKURL = "https://backend-honeyblue.maticz.in"

export const ZEROTH_ADDRESS = "0x0000000000000000000000000000000000000000";



export const getPredictionByName = (name) => {


  let GameData = {
    'Dice': [
      {
        "2x": "##predictdice1## == ##rollDice1##  || ##predictdice2## == ##rollDice2##"
      },
      {
        "4x": "##predictdice1## + ##predictdice2## == ##rollDice1## + ##rollDice2##"
      },
      {
        "8x": "##predictdice1## == ##rollDice1## && ##predictdice2##  == ##rollDice2##"
      },
      {
        "8x": "##predictdice1## == ##rollDice2## && ##predictdice2##  == ##rollDice1##"
      }
    ],
    'CoinFlip': [
      {
        "4x": "##predictcoin## == ##resultCoin##"
      }
    ],
    'RPC' : [
      {
        "4x": "##StoneRps## == ##predictRps## || ##ScissorRps## == ##predictRps## || ##PaperRps## == ##predictRps##"
      }
    ],
    'Wheel' : [
      {
       "2x" : "##predictWheel## == ##greyWheel##" 
      },
      {
        "3x" : "##predictWheel## == ##blueWheel##" 
       },
       {
        "6x" : "##predictWheel## == ##greenWheel##" 
       },
       {
        "48x" : "##predictWheel## == ##redWheel##" 
       }
    ],

    // 'Limbo' : [
    //   {
    //     "x" : "##predictionLimbo## >= ##RangeLimbo##" 
    //   }
    // ]

  }
  for (let i = 0; i < Object.keys(GameData)?.length; i++) {
    console.log("Object.keys(GameData)", name, Object.keys(GameData))
    if (Object.keys(GameData)[i] == name) {
      console.log("currentdataaaaaaaaaaa", Object.keys(GameData)[i], Object.values(GameData)[i]);
      return Object.values(GameData)[i]
    }

  }

}