import React, { useState, useEffect } from "react";
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BsArrowUpShort, BsArrowDownShort, BsQuestionCircle } from 'react-icons/bs';
import Sidebar from "../Layouts/sidebar1";
import Sidebarheader from "../Components/sidebarheader";
import refcoin from "../assets/refcoin.png";
import volumecoin from "../assets/volumecoin.png";
import rebatecoin from "../assets/rebatecoin.png";

import Footer from "../Layouts/Footer";
import Gameheader from "../Layouts/gamesheader";
import Referralmodal from '../Components/Modals/create_referral_modal';
import Referralcodetable from "../Components/referralcodetable";
import Referraltable from "../Components/referraltable";
import Referralhistorytable from "../Components/referralhistorytable";
import Referralbanktable from "../Components/referralbanktable";
import { CircularProgressbarWithChildren, } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import rightcoin from "../assets/rightcoin.png";
import leftcoin from "../assets/leftcoin.png";
import { getRefferRankInfo, getUserCliamDetailsHooks, getUserInfoHooks, getuserallorderdetails, getuserrefferaldetails, getuserallreffersdetailhooks, updateuserrefferaldetails, getrefferuserdetails, updaterefferalclaimdetails, getFeeInfo, updateAdminCommissionDetails } from "../hooks/UseUser";

import { BACKURL } from "../config/env";
import {  UseRefferalClaim, UseTokenInfo, UseUserWithdraw, getUserBalance, toFixedNumber } from "../hooks/UseContract";
import { stakeTokens } from "../config/Farms";
import { useLocation } from "react-router";
import { createTransactionHooks } from "../hooks/UseTransaction";
import toast from 'react-hot-toast';
import { position, style, iconTheme } from "../hooks/useToast";




export default function Earn() {


  const [userdata, setUserData] = useState({});
  const [rankDetails, setRankDetails] = useState([]);
  const [accountInfo, setAccountInfo] = useState('')
  const [referralmodal, setReferralmodal] = useState(false);
  const [refferalcount, setRefferalCount] = useState(0);
  const [refferalDetails, setRefferalDetails] = useState([]);
  const [balance, setBalance] = useState(0);
  const [alldepositdata, setAllDepositData] = useState(0);
  const [claimamount, setCliamAmount] = useState(0);
  const [totalamount, setTotalAmount] = useState(0);
  const [parentrefferaldata, setParentRefferalData] = useState({});
  const [ParentCliamEarned, setParentCliamEarned] = useState(0);
  const [minimumfee, setMinimumFee] = useState(0);
  // const [maximumfee, setMaximumFee] = useState(0);
  const [refferalclaimamount, setRefferalCliamAmount] = useState(0);
  console.log('refferalclaimamount shhhhh', refferalclaimamount)
  const [commissionEarned, setCommissionEarned] = useState(0)
  const [status, setStatus] = useState(false)
  const [levelpercentage, setLevelPercentage] = useState(0);
  const [leveldata, setLevelData] = useState([])

  const [feeInfo, setFeeInfo] = useState({})
  const [refferalfee, setRefferalFee] = useState(0)
  const [adminfee, setAdminFee] = useState(0)

  const [claimdata, setClaimData] = useState([])
  console.log("levelpercentage_levelpercentage",feeInfo, levelpercentage)
  let regex = /^\d+$/
  console.log("userddaaaaaaa",commissionEarned, claimamount,refferalcount, refferalDetails)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
let location = useLocation()

console.log('lcoation data asdfas434453534dfasd', location)
  useEffect(() => {
    async function fetchData() {
      let account = sessionStorage.getItem("accountInfo");
      setAccountInfo(account)
      let res = await getUserInfoHooks({ walletAddress: sessionStorage.getItem("accountInfo") });
      console.log('asdfas434453534dfasd', res?.data?.data, res?.data?.status);

      if (res?.data?.status == true) {
        console.log('2342 asdfas434453534dfasd', res?.data?.data, res?.data?.status);
        let result = res?.data?.data;
        let reqData = { id : result?._id};
        console.log('userall data', result)
        setUserData(result);
        getalluserorderdata()
        getrankDetails();
        getReffersDetails(reqData); // Anyone can you reffferdetails
        let tokenBalance = await getUserBalance(stakeTokens[0]?.address, account);
        setBalance(tokenBalance);
      }
      // GetContractBalance()
    }
    fetchData()
  }, [location, accountInfo, status])

  useEffect(() => {
    let reqData = { userId: userdata?._id }
    if(userdata?._id != undefined) {
      getRefferalData(reqData);// who can reffer me there details // My parentdetail
      console.log("commissionEarned_useEffect", commissionEarned)
      RefferLevel(commissionEarned);
    }
  }, [userdata, rankDetails, status, commissionEarned]);

  useEffect(() => {
    getClaimData();
    getRefferCliamData();
    getFeeInfoData();
    console.log('accountInfo, userdata, status', accountInfo, userdata, status)
}, [accountInfo, userdata, status]);

  useEffect(() => {
    let toFixedwithdrawfee = toFixedNumber(Number(feeInfo.WithdrawFee));
    // claimamount --> totalyouwonamount
    let toFixedtotalamount = toFixedNumber(Number(totalamount));
    console.log("toFixedtotalamount hfhf", toFixedtotalamount)
    let toFixedminimumfee = toFixedNumber(Number(minimumfee));
    //my parent ranklevel depends to come the minimumfee
    console.log("toFixedminimumfee hfhf", toFixedminimumfee)
    let referralpercentage = (Number(toFixedtotalamount) * Number(toFixedminimumfee)) / 100;
    console.log("referralpercentage hfhf", referralpercentage)
    setRefferalFee(referralpercentage)
    let adminpercentage = (Number(toFixedtotalamount) * Number(toFixedwithdrawfee)) / 100;
    console.log("adminpercentage hfhf", adminpercentage)
    setAdminFee(adminpercentage)
    let claimdata  = Number(totalamount) - (Number(referralpercentage) + Number(adminpercentage));
    console.log("claimdata", minimumfee, toFixedwithdrawfee,referralpercentage, adminpercentage,totalamount,claimdata, (Number(referralpercentage) + Number(adminpercentage)),
    Number(totalamount) - (Number(referralpercentage) + Number(adminpercentage)));

    setCliamAmount(claimdata)
  }, [minimumfee, feeInfo]);

  const getFeeInfoData = async () => {
    try {
      let res = await getFeeInfo();
      console.log("getfeedata", res?.data?.data)
      if (res?.data?.status == true) {
        setFeeInfo(res?.data?.data[0])
      }
    } catch (e) {
      console.log("getFeeInfoData_err", e)
    }
  }
  const RefferLevel = async (commissionEarned) => {
    try {
      let gradestage = rankDetails?.GradeStage;
      let checkstatus = true;
      let arr = [];

      for (let i = 0; i < gradestage?.length; i++) {
        let percentage; 
        let obj = gradestage[i];
        let stage = "Didn't start"


        // CommissionEarned != 0 only way enter all $ scenario
        // $
        console.log('RefferLevel_data', Number(gradestage[i].LevelPrice), Number(commissionEarned), checkstatus, (Number(gradestage[i].LevelPrice) >= Number(commissionEarned)) && checkstatus)
        if (Number(commissionEarned) != 0 && (Number(gradestage[i].LevelPrice) >= Number(commissionEarned)) && checkstatus) {
          percentage = (Number(commissionEarned) / Number(gradestage[i].LevelPrice)) * 100;
          checkstatus = false;
          console.log("111 RefferLevel_data", percentage, gradestage[i].Title)
          obj["levelprice"] = percentage;
        } else if (checkstatus == true) {
          percentage = (Number(commissionEarned) / Number(gradestage[i].LevelPrice)) * 100;
          obj["levelprice"] = percentage;
        } else if (checkstatus == false) {
          obj["levelprice"] = 0;
          stage = "Didn't start"
        }
        console.log("calcheck", Number(gradestage[i].LevelPrice) >= Number(commissionEarned), (Number(gradestage[i].LevelPrice)), checkstatus, percentage);

        if (Number(commissionEarned) == 0) {
          obj['levelprice'] = 0;
          stage = "Didn't start"
        }
        else if (1 >= Number(percentage)) {
          stage = "Initializing"
        }
        else if (25 >= Number(percentage)) {
          stage = "Start"
        } else if (50 >= Number(percentage)) {
          stage = "HalfCompleted"
        } else if (75 >= Number(percentage)) {
          stage = "HalfAlmostCompleted"
        } else if (100 >= Number(percentage) || (Number(commissionEarned) != 0 && checkstatus)) {
          stage = "Completed"
        }
        obj["stage"] = stage;
        arr.push(obj)
      }
      setLevelData(arr)
    console.log("arr push data", arr)

  } catch (e) {
    console.log("RefferLevel_err", e)
  }
}

  const getRefferalData = async (req) => {
    try {
      let res = await getuserrefferaldetails(req);
      //get my parent detail 

      let result = res?.data?.data;
      console.log("reswaitrefferaldata memee", result, res?.data?.status, rankDetails?.GradeStage)
      if (res?.data?.status == true) {
        setParentRefferalData(result);
        setParentCliamEarned(result?.CommissionEarned);
        let gradestage = rankDetails?.GradeStage;
        let checkstatus = true;
        let minimumfee = 0;
        // let maximumfee = 0;
        if (gradestage != undefined) {
          console.log("gradestage[i].LevelPrice memee", gradestage)
          for (let i = 0; i < gradestage?.length; i++) {
            console.log("gradestage[i].LevelPrice", Number(gradestage[i].LevelPrice), Number(result?.CommissionEarned), (Number(gradestage[i].LevelPrice) > Number(result?.CommissionEarned)), checkstatus,
              (Number(gradestage[i].LevelPrice) > Number(result?.CommissionEarned)) && checkstatus)
            if ((Number(gradestage[i].LevelPrice) > Number(result?.CommissionEarned)) && checkstatus) {
              minimumfee = gradestage[i].MinimumFee;
              checkstatus = false
            }
          }
          console.log("minimumfee, maximumfee memee", minimumfee)
          setMinimumFee(minimumfee)
          // setMaximumFee(maximumfee)
        }

      }

    } catch (e) {
      console.log("getRefferalData_err", e)
    }
  }

  const getalluserorderdata = async () => {
    try {
      let reqData = { walletAddress: accountInfo };
      console.log("reqdata", reqData)
      if (accountInfo != '' && accountInfo != undefined) {
        let res = await getuserallorderdetails(reqData);
        if (res?.data?.status == true) {
          let orderdatas = res?.data?.data;
          let sumdata = 0;
          for (let i = 0; i < orderdatas?.length; i++) {
            sumdata = sumdata + orderdatas[i].TotalWager
          }
          console.log("getalluserorderdata", orderdatas, sumdata)
          setAllDepositData(sumdata)
        }
      }

    } catch (e) {
      console.log("getalluserorderdata_err", e)
    }
  }


  const getrankDetails = async (req) => {
    try {
      let res = await getRefferRankInfo();
      console.log("getrefferankres", res?.data,res?.data?.data)
      if (res?.data?.status == true) {
        setRankDetails(res?.data?.data)
      } else {
        setRankDetails([])
      }
    } catch (e) {
      console.log("getrankDetails_err", e)
    }
  }

  const getReffersDetails = async (req) => {
    let res = await getuserallreffersdetailhooks(req);
    // Me who reffer , there counts & there details
    console.log("rrrrrrrrrr", res, res?.data?.status,res?.data?.record, res?.data?.count)
    if(res?.data?.status == true) {
      let result = res?.data?.record;
      setRefferalDetails(result)
      setRefferalCount(res?.data?.count)
    }
  }


const getClaimData = async () => {
  try {

      let reqData = {
          walletAddress: userdata?.WalletAddress, 
      }
      console.log("reqData kukue", reqData)
      let res = await getUserCliamDetailsHooks(reqData);
      console.log("respond_cliam kukue", res?.data,res?.data?.data);
      if(res?.data?.status == true) {
        let data = Array.isArray(res?.data?.data) ? [...res?.data?.data] : [];
        
        let arr = [];
        let sumgainamount = 0;
        if (data?.length > 0) {
            for (let i = 0; i < data.length; i++) {
                sumgainamount = Number(sumgainamount) + Number(data[i]?.GainClaimed);
                
                console.log('arrdataaaaaa',sumgainamount);

                let obj = {};
                console.log('data[i]', sumgainamount, data[i], (data[i]?.MultipleBets != data[i]?.RemainingBets), (data[i]?.RemainingBets > 0) , (data[i]?.GainClaimed > 0),
                ((data[i]?.MultipleBets != data[i]?.RemainingBets) && (data[i]?.RemainingBets > 0)) || (data[i]?.GainClaimed > 0));

                console.log("gamenamewith sumgaindata data[i]", data[i]?.GameName, data[i]?.MultipleBets, data[i]?.RemainingBets, (Number(data[i]?.RemainingBets) > 0), data[i]?.GainClaimed,  ((data[i]?.MultipleBets != data[i]?.RemainingBets) && (Number(data[i]?.RemainingBets) > 0)) || (data[i]?.GainClaimed > 0))
                if(((Number(data[i]?.MultipleBets) != Number(data[i]?.RemainingBets)) && (Number(data[i]?.RemainingBets) > 0)) || (data[i]?.GainClaimed > 0)) {
                  console.log("gamenamewith sumgaindata data[i]", data[i]?.GameName, i)
                  obj['gainclaimed'] = data[i]?.GainClaimed;
                  obj['_id'] = data[i]?._id;
                  obj['remainingbets'] = data[i]?.RemainingBets;
                  obj['multiplebets'] = data[i]?.MultipleBets;
                  obj['gameName'] = data[i]?.GameName;
                  obj['walletAddress'] = data[i]?.WalletAddress;
                  obj['status'] = "false";
                  console.log('111 sumgaindata data[i]', obj,data[i], data[i]?.GameName)
                  arr.push(obj)
                } else if(data[i]?.RemainingBets == 0) {
                  obj['gainclaimed'] = data[i]?.GainClaimed;
                  obj['_id'] = data[i]?._id;
                  obj['remainingbets'] = data[i]?.RemainingBets;
                  obj['multiplebets'] = data[i]?.MultipleBets;
                  obj['gameName'] = data[i]?.GameName;
                  obj['walletAddress'] = data[i]?.WalletAddress;
                  obj['status'] = "true";
                  console.log('222 sumgaindata data[i]', obj,data[i], data[i]?.GameName)

                  arr.push(obj)
                }

                console.log('arrdataaaaaaobjjjj',obj)
            };
           console.log("that arr data", arr)

           console.log('arrdataaaaaa alll',data, sumgainamount);
            setTotalAmount(sumgainamount)
            setClaimData(arr)
        }
      } else {
        setTotalAmount(0)
      }

// let addgetclai
  } catch (e) {
      console.log('getClaimData_err', e)
  }
}

  const getRefferCliamData = async () => {
    try { 
      // my claimdata details
      if (userdata?._id != undefined) {
        let res = await getrefferuserdetails(userdata?._id);
        console.log('getRefferCliamData', res?.data?.data);
        let result = res?.data?.data;
        console.log('resulttt shhhhh', result, Number(result?.CommissionCliamed));
        let toFixed = toFixedNumber(Number(result?.CommissionCliamed))
        console.log('toFixed_toFixed', toFixed, result?.CommissionEarned)
        setRefferalCliamAmount(Number(toFixed));
        setCommissionEarned(Number(result?.CommissionEarned))
        // let refferalranklevel = Number(result?.CommissionEarned);

      }
    } catch (e) {
      console.log("getRefferCliamData_err", e)
    }
  }



  const Claim = async () => {
    try {
      console.log('cliamamountdetails percentagefee_percentagefee', claimamount, minimumfee, claimdata);
      if (claimamount != 0) {
        if(claimamount >= 0.0001) {
          let WINRToken = stakeTokens[0].address;
          let tokenInfo = await UseTokenInfo(WINRToken);
          console.log('tokenInfodeatails', tokenInfo)
          let amountdata = parseFloat(claimamount) * 10 ** parseInt(tokenInfo.decimals);
          let toFixedAmount = toFixedNumber(Number(amountdata));
          console.log("Claim_data", claimamount, amountdata, toFixedAmount)
          // let withdraw = await UseUserWithdraw(toFixedAmount, WINRToken, accountInfo);
          let withdraw = await UseRefferalClaim(toFixedAmount, WINRToken, accountInfo);
          if (withdraw != undefined) {
          let reqData = { id: userdata?._id, commissionfee: refferalfee.toString(), walletAddress: accountInfo, claimdata : claimdata };
          let res = await updateuserrefferaldetails(reqData);
          console.log('rrrrrrrrrrrrr', res, res?.data?.status)
          if (res?.data?.status == true) {
            console.log("getClaimData kukue", res?.data)
            setStatus(!status)
          }
            let reqtransdata = {
              walletAddress: accountInfo, userId: userdata?._id, parentId: parentrefferaldata?.parentId, gameName : "",
              amount: claimamount, commissionfee: adminfee, refferalfee: refferalfee, totalamount: totalamount,
              status: 'Completed', type: 'Withdraw', transtype : "Commission"
            }
            let createTransac = await createTransactionHooks(reqtransdata);
            let updatedata = { commissionfee : adminfee}
            let updateCommissionFee = await updateAdminCommissionDetails(updatedata)
            console.log("rssssssssssssss", updateCommissionFee)
            console.log("createTransac_createTransac",createTransac?.data)
          }
        } else {
        //  toast
        await toast.error(`Minimum withdraw atleast 0.0001`,
        {
            duration: 4000,
            position: position.position,
            style: style,
            iconTheme: iconTheme
        });
        }
      }
    } catch (e) {
      console.log("Cliam_err", e)
    }
  }

  const RefferalClaim = async () => {
    try {
      if (refferalclaimamount != 0) {
        let refferalamount = refferalclaimamount;
        let WINRToken = stakeTokens[0].address
        let tokenInfo = await UseTokenInfo(WINRToken);
        let amountdata = parseFloat(refferalamount) * 10 ** parseInt(tokenInfo.decimals);
        console.log("amountdata_data", refferalamount, amountdata, parseInt(amountdata))
        let toFixedAmount = toFixedNumber(parseInt(amountdata));
        console.log("Claim_data", refferalamount, amountdata, toFixedAmount)
        let claim = await UseRefferalClaim(toFixedAmount, WINRToken, accountInfo);
        console.log("claimdasadfasdfta", claim)
        if (claim != undefined) {
          let reqdata = { id: userdata?._id, commissionclaimed: refferalclaimamount }
          let reqtransdata = {
            walletAddress: accountInfo, userId: userdata?._id, parentId: "", gameName: "",
            amount: "", commissionfee: "", refferalfee: refferalclaimamount, totalamount: refferalclaimamount,
            status: 'Completed', type: 'Withdraw', transtype: "Reffer",
          }
          let createTransac = await createTransactionHooks(reqtransdata);
          console.log("createTransac_createTransac", createTransac?.data)
          let res = await updaterefferalclaimdetails(reqdata);
          if (res?.data?.status == true && createTransac?.data?.status == true) {
            setStatus(!status)
          }
          
        }

      }
    } catch (e) {
      console.log("RefferalCliam_err", e)
    }
  }
  return (
    <>
      {referralmodal && <Referralmodal onDismiss={() => setReferralmodal(false)} />}
      <div className="sidebarlist">
        <Gameheader />
        <div className="d-none d-xl-block"><Sidebar /></div>

        <div className="content pt-3">
          <div className="container container_max">
            {/* <Sidebarheader /> */}

            <div className="content_right position pt-5">
            <img src={rightcoin} className="rightcoin flip" alt="rightcoin"/>
            <img src={leftcoin} className="leftcoin flip" alt="leftcoin"/>
            <h4 className="pt-4 pb-3 fw-700 whtclr">Earn Rebates from <span className="referral">Referral</span></h4>

            <div className="row">
              <div className="col-md-4 mt-3">
                <div className="referralticket referralticket1 d-flex">
                  <img className="img-fluid" src={refcoin} alt="contest" />
                  <div className="ms-3"><h5 className="fw-800 referralheading whtclr">Total Players Referred</h5><p className="mb-0 fw-700">{refferalcount} Players</p></div>
                </div>
              </div>
              {/* <div className="col-md-4 mt-3">
                <div className="referralticket d-flex">
                  <img className="img-fluid" src={volumecoin} alt="contest" />
                  <div className="ms-3"><h5 className="fw-800 referralheading">Total Edge Volume</h5><p className="mb-0 fw-700">$ 0</p></div>
                </div>
              </div>

              <div className="col-md-4 mt-3">
                <div className="referralticket d-flex">
                  <img className="img-fluid" src={rebatecoin} alt="contest" />
                  <div className="ms-3"><h5 className="fw-800 referralheading">Total Rebates</h5><p className="mb-0 fw-700">$ 0</p></div>
                </div>
              </div> */}

              <div className="col-md-4 mt-3">
                <div className="referralticket referralticket2 d-flex">
                  <img className="img-fluid" src={rebatecoin} alt="contest" />
                  <div className="ms-3"><h5 className="fw-800 referralheading whtclr">Total WINR Balance</h5><p className="mb-0 fw-700">$ {balance}</p></div>
                </div>
              </div>

              <div className="col-md-4 mt-3">
                <div className="referralticket referralticket3 d-flex">
                  <img className="img-fluid" src={rebatecoin} alt="contest" />
                  <div className="ms-3"><h5 className="fw-800 referralheading whtclr">Deposit Amounts</h5><p className="mb-0 fw-700">$ {alldepositdata}</p></div>
                </div>
              </div>
            </div>


            <div className="row pt-3 pb-4">
              <div className="col-md-6 mx-auto mt-3">
                <div className="gameborder referborder heightborder">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="fw-700 mb-0 whtclr">Your Referral Codes</h5>
                    {/* <button className="btn btn-outline-warning" onClick={() => setReferralmodal(true)}>CREATE</button> */}
                  </div>
                  <div className="mt-4">
                    <Referralcodetable userdata={userdata} />
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6 mt-3">
                <div className="gameborder referborder heightborder">
                  <h5 className="fw-700">Enter Referral Code</h5>
                  <p className="f-14">Please input a referral code to benefit the increasing vWINR minting rate</p>
                  <div className="row">
                    <div className="col-md-6 mt-3"><div className="input-group">
                      <input type="text" className="form-control" />
                      
                      <span class="input-group-text" id="basic-addon1"><img className="mr-3" src={pigcoin} alt="images" /></span></div></div>
                    <div className="col-md-6 mt-3"><h6 className="fw-600">Your vWINR Minting Rate</h6><p>5%</p></div>
                  </div>
                </div>
              </div> */}
            </div>

            <div className="gameborder referborder">
            <div className="row align-items-center">
                <div className="col-md-7">
                  <h5 className="fw-700 whtclr">Refferal Claim Rebates <span className="lgray fw-600 ms-2">Rewards become available for claim every 24 hours, at 3 PM UTC.</span></h5>
                </div>
                <div className="col-md-5">
                  <div className="row align-items-end">
                    {console.log('refferalclaimamount_refferalclaimamount', refferalclaimamount)}
                    {console.log("reff shhhhh", refferalclaimamount, refferalclaimamount != 0, refferalclaimamount,refferalclaimamount != 0  ? refferalclaimamount?.toFixed(7) : refferalclaimamount)}
                    <div className="col-md-7 mt-3"> <p className="d-flex justify-content-between mb-2"><span className="fw-700">Rewards</span><span className="fw-600">$ {refferalclaimamount != 0  ? refferalclaimamount?.toFixed(7) : refferalclaimamount}</span></p>
                      <input type="text" className="form-control" value={refferalclaimamount != 0  ? refferalclaimamount?.toFixed(7) : refferalclaimamount} />
                    </div>
                    <div className="col-md-5 mt-3">
                      {console.log("cliamresult", claimamount <  0, claimamount)}
                      <button type="button" class="btn w-100 btn-success earnbtn walletss walletss_sm"  disabled ={refferalclaimamount > 0 ? false : true} onClick={() => RefferalClaim()}
                      >REFFERAL CLAIM REBATES</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center mt-4 mt-lg-0">
                <div className="col-md-7">
                  <h5 className="fw-700 whtclr">Claim Rebates <span className="lgray fw-600 ms-2">Rewards become available for claim every 24 hours, at 3 PM UTC.</span></h5>
                </div>
                <div className="col-md-5">
                  <div className="row align-items-end">
                    <div className="col-md-7 mt-3"> <p className="d-flex justify-content-between mb-2"><span className="fw-700">Rewards</span>
                    <div>
                    <span className="fw-600">$ {claimamount != 0 ? claimamount?.toFixed(7) > 0 ? claimamount?.toFixed(7) : claimamount?.toFixed(8) > 0 ? claimamount?.toFixed(8) : claimamount?.toFixed(9) > 0 ? claimamount?.toFixed(9) : claimamount?.toFixed(10) > 0 ? claimamount?.toFixed(10) : claimamount?.toFixed(11) : claimamount}</span>
                    
                   {refferalfee != 0 && <OverlayTrigger
                                            className="toltip_style"
                                            key="top"
                                            placement="top"
                                            overlay={
                                                <Tooltip id={`tooltip-top`}>
                                                  ${refferalfee} in your balance has been sent to your referrer
                                                </Tooltip>
                                            }
                                        >
                                            <Button className="bet_overlay_icon"><BsQuestionCircle className="que" /></Button>
                                        </OverlayTrigger>}
                                        </div>
                    </p>
                      <input type="text" className="form-control" value={claimamount != 0 ? claimamount?.toFixed(7) : claimamount} />
                    </div>
                    <div className="col-md-5 mt-3">
                      {console.log("cliamresult", claimamount <  0, claimamount)}
                      <button type="button" class="btn w-100 btn-success earnbtn walletss walletss_sm"  disabled ={claimamount >  0 ? false : true} onClick={() => Claim()}
                      >CLAIM REBATE</button>
                    </div>
                  </div>
                </div>
              </div>

              
            </div>

            <h5 className="fw-700 mt-4 mb-3 whtclr">Rank</h5>
            <div className="row">
              {console.log("valdatrankDetailssgal", rankDetails)}
              {leveldata && leveldata?.length > 0 && leveldata?.map((val, ind) => {
                return (<>
                  {console.log("22 valdatsgal", val, val?.levelprice)}
                  <div className="col-sm-6 col-lg-3 col-md-6 mt-3 position-relative">
                    <div className="circlelist" style={{ width: 160, height: 160, margin: "auto" }}>
                      <div class="rank">{ind + 1}</div>
                      <div class="circular-progress_tick"><svg width="9" height="7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m1 3 3 2 4-4" stroke="#141D2B" stroke-width="2" stroke-linecap="round"></path></svg></div>
                      <CircularProgressbarWithChildren value={val?.levelprice}>
                        {console.log("valllllllllll", val)}
                        {val?.stage == "Didn't start" ? <img src={BACKURL + `/Images/6.png`} alt="doge" /> : val?.stage == "Initializing" ? <img src={BACKURL + `/Images/6.png`} alt="doge" /> : val?.stage == "Start" ? <img src={BACKURL + `/Images/4.png`} alt="doge" /> :
                          val?.stage == "HalfCompleted" ? <img src={BACKURL + `/Images/HalfLevel.png`} alt="doge" /> : val?.stage == "HalfAlmostCompleted" ? <img src={BACKURL + `/Images/1.png`} alt="doge" /> : val?.stage == "Completed" && <img src={BACKURL + `/Images/3.png`} alt="doge" />}

                      </CircularProgressbarWithChildren>
                    </div>
                    <h5 className="fw-700 mt-3 mb-2 text-center whtclr">{val?.Title}</h5>
                    {/* <p className="text-center mb-3 greentext">Reached</p> */}
                    <p className="circlelast">{`${val?.MinimumFee}% of the edge of the game in WLP`}</p>
                  </div>
                </>)
              })
            
            

              
              
              
              }
              {/* <div className="col-sm-6 col-lg-3 col-md-6 mt-3 position-relative">
                <div className="circlelist" style={{ width: 160, height: 160, margin: "auto" }}>
                  <div class="rank">1</div>
                  <div class="circular-progress_tick"><svg width="9" height="7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m1 3 3 2 4-4" stroke="#141D2B" stroke-width="2" stroke-linecap="round"></path></svg></div>
                  <CircularProgressbarWithChildren value={100}>

                    <img src={greencircle} alt="doge" />
                  
                  </CircularProgressbarWithChildren>
                </div>
                <h5 className="fw-700 mt-3 mb-2 text-center">Friend Finder</h5>
                <p className="text-center mb-3 greentext">Reached</p>
                <p className="circlelast">5% of the edge of the game in WLP</p>
              </div>
              <div className="col-sm-6 col-lg-3 col-md-6 mt-3 position-relative">
                <div className="circlelist" style={{ width: 160, height: 160, margin: "auto" }}>
                  <div class="rank">2</div>
                  <CircularProgressbarWithChildren value={0}>
                    <img src={goldcircle} alt="doge" />
                  </CircularProgressbarWithChildren>
                </div>
                <h5 className="fw-700 mt-3 mb-2 text-center">Social Butterfly</h5>
                <p className="text-center mb-3 allnos"><span className="nclr">$0</span> / $250,000</p>
                <p className="circlelast">7.5% of the edge of the game in WLP</p>
              </div>
              <div className="col-sm-6 col-lg-3 col-md-6 mt-3 position-relative">
                <div className="circlelist" style={{ width: 160, height: 160, margin: "auto" }}>
                  <div class="rank">3</div>
                  <CircularProgressbarWithChildren value={0}>
                    <img src={goldcircle} alt="doge" />
                  </CircularProgressbarWithChildren>
                </div>
                <h5 className="fw-700 mt-3 mb-2 text-center">Community Leader</h5>
                <p className="text-center mb-3 allnos"><span className="nclr">$0</span> / $2,000,000</p>
                <p className="circlelast">10% of the edge of the game in WLP</p>
              </div>
              <div className="col-sm-6 col-lg-3 col-md-6 mt-3 position-relative">
                <div className="circlelist" style={{ width: 160, height: 160, margin: "auto" }}>
                  <div class="rank">4</div>
                  <CircularProgressbarWithChildren value={0}>
                    <img src={goldcircle} alt="doge" />
                  </CircularProgressbarWithChildren>
                </div>
                <h5 className="fw-700 mt-3 mb-2 text-center">Influencer</h5>
                <p className="text-center mb-3 allnos"><span className="nclr">$0</span> / $10,000,000</p>
                <p className="circlelast">15% of the edge of the game in WLP</p>
              </div> */}
            </div>

            <div className="row pt-3 pb-4">
              <div className="col-md-6 mt-3">
                <h5 className="fw-700 mt-3 mb-3 whtclr">Your Referrals</h5>
                <div>
                  <Referraltable refferalDetails={refferalDetails}/>
                </div>
              </div>
              {/* <div className="col-md-6 mt-3">
                <h5 className="fw-700  mt-3  mb-3">Rewards Distribution History</h5>
                <div>
                  <Referralhistorytable />
                </div>
              </div> */}
              {/* <div className="col-md-12 mt-4">
                <h5 className="fw-700 mt-3 mb-3">Referral Rank</h5>
                <div>
                  <Referralbanktable />
                </div>
              </div> */}
            </div>

          </div>
          </div>


          <Footer />
        </div>


      </div>
    </>
  );
}

