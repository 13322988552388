import React, { useState, useEffect } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { MdOutlineContentCopy } from "react-icons/md";
import { copyUrl } from "../hooks/UseUser";
import { FRONTENDURL } from "../config/env";

export default function Gametable(props) {


  const [userdata, setUserData] = useState({});
  const [accountInfo, setAccountInfo] = useState("");
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        setUserData(props?.userdata)
    }, [props])


    useEffect(() => {
       const walletAddress = sessionStorage.accountInfo ? setAccountInfo(sessionStorage.getItem("accountInfo")) : ""
   }, [accountInfo]);

    const [gametable, setgametable] = useState([
        { "id": 1, }, { "id": 2, }, { "id": 3, }, { "id": 4, }, { "id": 5, }, { "id": 6, }, { "id": 7, }, { "id": 8, }, { "id": 9, }, { "id": 10, },
    ]);

    return (
        <>
            <div className="alltable refalltable">          
                <table class="table table-hover table_gmeaccou ref_table">
                    <thead>
                        <tr className="th_clr">
                            <th scope="col">Referral Code</th>
                            {/* <th scope="col">WLP Rebate Rate</th>
                            <th scope="col">vWINR Rebate Rate</th>                         */}
                        </tr>
                    </thead>
                    <tbody>
                        {/* {gametable.map((e, i) => */}
                            <tr>
                                <td className="d-flex justify-content-between">
                                    <div>{userdata?.RefferalCode}</div>
                                    {/* onCopy={() => copyUrl('Copied Url', `${FRONTENDURL}/${userdetails?.merchantsetting[0]?.merchantId}`)} */}
                                    <div className="cur_pointer_copy">
                                        {console.log("accountInfo_data", accountInfo)}
                                   {(!(accountInfo == "" || accountInfo == null)) && <CopyToClipboard text={`${FRONTENDURL}${userdata?.RefferalCode}`}  onCopy={() => { copyUrl('Copied Url',`${FRONTENDURL}/${userdata?.RefferalCode}`)}}>
                                       <MdOutlineContentCopy className="copysvg"/>
                                  </CopyToClipboard>}
                                  </div>
                                
                                </td>
                                {/* <td>234.21</td>
                                <td>12.85</td>                                */}
                            </tr>
                        {/* )} */}
                    </tbody>
                </table>

            </div>

        </>
    );
}