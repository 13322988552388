import React from "react";
import toast, { Toaster } from 'react-hot-toast';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './Styles/style.css';
import './Styles/game.css';
import './Styles/gamelatest.css';
import Home from './Pages/Home';
import Games from './Pages/games';
import Gamesdetail from './Pages/gamedetail';
import Gamecoin from './Pages/Gamecoin';
import Earn from './Pages/earn';
import Stake from './Pages/stake1';
import Referral from './Pages/referral';
import HelperRoutes from "./helper";

//socket
import SocketContext from './Components/Content/socketContext';
// import HelperRoutes from './routes/helper';
import { socket } from './config/socketConnectivity';
import Redirectsite from "./Pages/Redirectsite";
// import Coinflip from './Pages/coinflipgame';

export default function App() {
  return (
    <>
<div><Toaster /></div>
    <BrowserRouter >
    <SocketContext.Provider value={{ socket  }}>
    <HelperRoutes />
      <Routes>
        <Route path="/" element={<Redirectsite />} />
        <Route path="/Games" element={<Games />} />
        <Route path="/games/:name" element={<Gamesdetail />} />
        <Route path="/gamecoin" element={<Gamecoin />} />
        <Route path="/stake" element={<Stake />} />
        <Route path="/referral" element={<Referral />} />
        <Route path="/earn" element={<Earn />} />
        <Route path="/:refferalId" element={<Home />} />

        {/* <Route path="/coinflip" element={<Coinflip />} /> */}

      </Routes>
      </SocketContext.Provider>
     </BrowserRouter>

    </>
  );
}



// import React, { useState, useEffect} from 'react';
// import './PlinkoGame.css'; // Import the CSS file for styling

// // Define the pegged board structure
// const board = Array.from({ length: 12 }, () => Array(12).fill(1)); // Create a 12x12 board with all pegs

// const PlinkoGame = () => {
//   const [ballPosition, setBallPosition] = useState({ });
//   console.log('ballpositindatasgal', ballPosition) // Start the ball at the top middle
//   const [prize, setPrize] = useState(null);
//   const [isDropping, setIsDropping] = useState(false);


//   function getRandomInt(min, max) {
//     return Math.floor(Math.random() * (max - min + 1)) + min;
//   }

//   var row;
//   var columns;
//   var resultarr = [];
//   useEffect(() => {


//     for (let i = 11; i >= 0; i--) {
//       let data;
//       console.log('rowdatasdfasdf', i, row, columns)
//       if (!row && !columns) {
//         data = [11, 3]
//       } else {
//         data = [row, columns]
//       }
//       console.log('dafasdfasdfasrew', data)
//       if (data) {
//         console.log('ddddddddd', data);
//         let lefdata = data[1] - 1;
//         let rigdata = data[1] + 1;
//         console.log('left_rig', lefdata, rigdata);
//         let arr1 = []
//         if (0 < lefdata) {
//           arr1.push([i, lefdata])
//         }
//         if (rigdata <= 12) {
//           arr1.push([i, rigdata])
//         }
//         let min = 0;
//         let max = arr1?.length - 1;
//         const randomColumn = getRandomInt(min, max);
//         console.log('randocolumnsdata', arr1, randomColumn, arr1, arr1[randomColumn], arr1[randomColumn][0], arr1[randomColumn][1]);
//         row = arr1[randomColumn][0];
//         columns = arr1[randomColumn][1];
//         resultarr.unshift(arr1[randomColumn])
//       }
//     }
//     console.log('resultplin arr data', resultarr)
//   }, [])

  
//   const dropBall = () => {

//     try {

//       for (let i = 11; i >= 0; i--) {
//         let data;
//         console.log('rowdatasdfasdf', i, row, columns)
//         if (!row && !columns) {
//           data = [11, 3]
//         } else {
//           data = [row, columns]
//         }
//         console.log('dafasdfasdfasrew', data)
//         if (data) {
//           console.log('ddddddddd', data);
//           let lefdata = data[1] - 1;
//           let rigdata = data[1] + 1;
//           console.log('left_rig', lefdata, rigdata);
//           let arr1 = []
//           if (0 < lefdata) {
//             arr1.push([i, lefdata])
//           }
//           if (rigdata <= 12) {
//             arr1.push([i, rigdata])
//           }
//           let min = 0;
//           let max = arr1?.length - 1;
//           const randomColumn = getRandomInt(min, max);
//           console.log('randocolumnsdata', arr1, randomColumn, arr1, arr1[randomColumn], arr1[randomColumn][0], arr1[randomColumn][1]);
//           row = arr1[randomColumn][0];
//           columns = arr1[randomColumn][1];
//           resultarr.unshift(arr1[randomColumn])
//         }
//       }
//       setIsDropping(true);
//       let currentRow = 0;
//       let currentColumn = 5; 
//       let i = 0;// Start in the middle column
  
//       const dropInterval = setInterval(() => {
//         const direction = Math.random() < 0.5 ? -1 : 1; // Randomly choose left or right
//         console.log('dir_data', currentColumn, direction, currentColumn + direction, board[0]?.length, board[currentRow][currentColumn + direction])
//         if (currentColumn + direction >= 0 && currentColumn + direction < board[0].length && board[currentRow][currentColumn + direction] === 1) {
//           // If there's a peg, move in the chosen direction
//           currentColumn += direction;
//         }

//         let rowdata = resultarr[currentRow][0]
//         let columndata = resultarr[currentRow][1]
//         console.log('aasfdasare', currentRow, rowdata, columndata);
//         setBallPosition({ row : rowdata, column : columndata})
//         currentRow++;

//         if (currentRow === board.length) {
//           clearInterval(dropInterval);
//           setIsDropping(false);
//           setPrize(determinePrize(currentColumn)); // Determine the prize
//         }
//       }, 800);
//     } catch (e) {
//       console.log('dropBall_errrr', e)
//     }
//  // Adjust animation speed as needed
//   };

//   // Determine the prize based on the ball's position
//   const determinePrize = (slot) => {

//     console.log('11 currentRow_currentColumn', slot)
//     // Define your prize logic based on the slot
//     // For example:
//     if (slot === 0) {
//       return '$100';
//     } else if (slot === 1) {
//       return '$200';
//     } else {
//       return 'No prize';
//     }
//   };

//   return (
//     <div className="plinko-container">
//       <div className="board">
//         {board.map((row, rowIndex) => (
//           <div key={rowIndex} className="row">
//             {row.map((peg, columnIndex) => 
//             {
//               {console.log('ballPosition.row ballpositindatasgal', ballPosition.row, rowIndex, ballPosition.column, columnIndex)}
//               return(
//                 <>
//                 <div key={columnIndex} className={`peg ${ballPosition.row === rowIndex && ballPosition.column === columnIndex ? 'ball' : ''}`}></div>
//                 </>
//               )
//             })}
//           </div>
//         ))}
//       </div>
//       <button onClick={dropBall} disabled={isDropping}>
//         {isDropping ? 'Dropping...' : 'Drop Ball'}
//       </button>
//       <div className="result">Prize: {prize}</div>
//     </div>
//   );
// };

// export default PlinkoGame;
