import React, { useState, useEffect } from "react";
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import Logo from '../assets/logo1.png';
import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import Sidebar from "../Layouts/sidebar1";
import Sidebarheader from "../Components/sidebarheader";
import Gameheader from '../Layouts/gamesheader';
import Gametable from "../Components/gametabel";
import { BsStarFill } from 'react-icons/bs';
import { Link, useLocation } from "react-router-dom";
import tablecoin from '../assets/tablecoin.png';
import volumeimg from '../assets/volume.png';
import { BsArrowUpShort, BsArrowDownShort, BsQuestionCircle } from 'react-icons/bs';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import Dicemodal from '../Components/Modals/dicemodal';
import Dice from "./Dice"
import Wallets from '../Components/Modals/Wallet';
import { getAccount } from "../hooks/useAccount";
import { approveGame, checkIsApprovedGame, convertScientificToWholeNumber, getUserBalance, toFixedNumber, UseTokenInfo, UseUserBet } from "../hooks/UseContract";
import { stakeTokens } from "../config/Farms";
import { getWholeNumber, OnStake } from "../hooks/UseStake";
import { getFeeInfo, getgamesInfoHooks, getOrderDetailsHooks, getOrderTransactionDetailsHooks, getRefferInfo, getUserInfoHooks, Toast, UpdateOrderDetailsHooks, updateAdminCommissionDetails, getGameSettingDetails, getAllGameTransactionDetails, } from "../hooks/UseUser";
import { getGameAllTransactionInfoHooks, getgametransdetails } from "../hooks/UseTransaction";
import { createOrderdetails, GamePrediction, setlocalStoragegamebet, ToastBet } from "../hooks/useBot";
import toast from "react-hot-toast";


import Coinflippage from "./coinflipgame";
import Rpspage from "./rpsgame";
import Wheelpage from "./wheelgame";
import Limbogame from "./Limbogame";
import Plinkogame from './plinkogame'
import Plink from './plink'
// import Moongame from "./Moongame";
import Moongametwo from "./Moongametwo";
import Rangegame from "./Rangegame";

import { getAllGamesInfoHooks } from "../hooks/UseUser";
import { FRONTENDURL, BACKURL, getPredictionByName } from "../config/env";
import { createTransactionHooks } from "../hooks/UseTransaction";
import { iconTheme, position, style } from "../hooks/useToast";
// import Plinkopage from "./plinkogame";

import flip1 from "../assets/flip1.png";
import flip2 from "../assets/flip2.png";
import flip3 from "../assets/flip3.png";
import flip4 from "../assets/flip4.png";
import flip5 from "../assets/flip5.png";

import splashimg from '../assets/sidemenu/splash1.webp'
import playbtn from '../assets/sidemenu/playbtn.png'
export default function Games() {
    const [cardhover,setCardhover]= useState(false)
    const [selected, setSelected] = useState([]);
    console.log("selected_data", selected, selected.length, selected?.length >= 2)
    const [volumeup, setvolumeup] = useState(0)
    const [volumes, setvolumes] = useState(1)
    const [volumess, setvolumess] = useState(0.0001)
    const [dicemodal, setdicemodal] = useState(false)
    const [walletmodal, setWalletmodal] = useState(false)

    const [counter, setCounter] = useState(0);

    //venkatesh
    const [accountInfo, setAccountInfo] = useState("");
    const [range, setRange] = useState("0");
    console.log("range_dice", range)
    const [depositTokenBalance, setDepositTokenBalance] = useState(0);
    const [isApproved, setIsApproved] = useState(false);
    const [pendingTx, setpendingTx] = useState(true);
    const [isApprovedAmount, setIsApprovedAmount] = useState()


    const [betamount, setBetAmount] = useState(1);
    const [prediction, setPrediction] = useState(0);
    const [play, setPlay] = useState(false);
    const [depositamount, setDepositAmount] = useState(0);
    const [transactionInfo, setTransactionInfo] = useState([]);
    const [onemonthwonInfo, setOneMonthWonInfo] = useState([]);
    const [oneweekwonInfo, setOneWeekWonInfo] = useState([]);
    const [check, setCheck] = useState(false);
    const [gameInfo, setGameInfo] = useState({})
    console.log("eeeee juju 111111", gameInfo, gameInfo && gameInfo?.GamePlay?.length > 0 && gameInfo?.GamePlay[0]);
    const [winchance, setWinChance] = useState(0);
    const [bet, setBet] = useState(false);
    const [pathName, setPathName] = useState('');
    const [start, setStart] = useState(true);
    const [tossstart, setTossStart] = useState(true)
    const [rpcstart, setRpcStart] = useState(true)
    const [wheelstart, setWheelStart] = useState(true)
    const [wager, setWager] = useState(0.0001);
    const [multiplebets, setMultipleBets] = useState(1);
    const [usergamedetails, setUserGameDetails] = useState({});
    const [remainingbets, setRemainingBets] = useState(0)
    console.log('remainingbetsdata', remainingbets)
    const [stoploss, setStopLoss] = useState(0);
    const [stopgain, setStopGain] = useState(0);
    const [checkOrder, setCheckOrder] = useState(false);
    const [gameCard, setGameCard] = useState([]);
    const [selectedcoin, setSelectedCoin] = useState("");
    const [intialselectedcoin, setInitalSelectedCoin] = useState("");
    const [disabledtossbtn, setDisabledtossBtn] = useState(false)
    const [selectedRpc, setSelectedRpc] = useState("");
    const [disabledRpcbtn, setDisabledRpcBtn] = useState(false);
    const [disabledWheelbtn, setDisabledWheelBtn] = useState(false);
    const [initialSelectedRpc, setInitialSelectedRpc] = useState('')
    const [initialSelectedWheel, setInitialSelectedWheel] = useState('')
    const [disabledPlinkobtn, setDisabledPlinkoBtn] = useState(false)
    const [selectedWheel, setSelectedWheel] = useState();
    console.log("datasdfasd", selectedRpc, bet)
    const [initialSelectedLimbo, setInitialSelectedLimbo] = useState('')
    const [disabledlimbobtn, setDisabledLimboBtn] = useState(false);
    const [limbostart, setLimboStart] = useState(true)
    const [selectedLimbo, setSelectedLimbo] = useState()
    const [moonstart, setMoonStart] = useState(true) // true 
    const [rangestart, setRangeStart] = useState(true)
    const [plinkostart, setPlinkoStart] = useState(true)
    const [initalSelectedMoon, setInitalSelectedMoon] = useState('')
    const [disabledmoonbtn, setDisabledMoonBtn] = useState(false);
    const [maxpay, setMaxPay] = useState(0);
    const [maxpayout, setMaxPayout] = useState(0);
    const [limbodragval, setLimboDragval] = useState(0)
    const [feeInfo, setFeeInfo] = useState({});
    const [userdetails, setUserDetails] = useState({})
    const [refferdetails, setRefferDetails] = useState()
    const [adminclaimfee, setAdminClaimFee] = useState(0)
    const [transfilter, setTransFilter] = useState("personal")
    const [gametrans, setGameTrans] = useState([])
    const [gamesetting, setGameSetting] = useState({})
    const [allgametransacdata, setAllGameTransData] = useState([]);

    const [initialselectedrange, setInitialSelectedRange] = useState(0)
    const [disabledrangebtn, setDisabledRangeBtn] = useState(false)
    const [rangemaxpayout, setRangeMaxPayout] = useState()
    const [selectedrange, setSelectedRange] = useState('')
    const [error, setError] = useState('')
    const [error1, setError1] = useState('')
    const [calculatewager, setCalculateWager] = useState(0)
    const [feedata, setFeeData] = useState(false);
    const [rangeval, setRangeVal] = useState("")
    const [plinkobets, setPlinkoBets] = useState(12)
    console.log("plinkobets_plinkobets", plinkobets)

    const [wagerclaimfee, setWagerClaimFee] = useState(0)
    const [gamepercentage, setGamePercentage] = useState([])
    const [rangemultiplier, setRangeMultiplier] = useState(0);
    console.log('checka all rangmul', rangemultiplier)
    const [rangearray, setRangeArray] = useState([])
    const [checkrangeval, setCheckRangeval] = useState(0);
    const [checkcount, setCheckCount] = useState(0);
    const [wagerr, setWagErr] = useState('');
    const [status, setStatus] = useState(false)
    console.log("bet dasdfasdfsata", wagerclaimfee, limbodragval, bet, limbostart)

    console.log("checkordet_stat", checkOrder, selectedcoin, intialselectedcoin)

    let decregex = /\d+\.?\d*/;
    let regex = /^[0-9]+$/;
    console.log("betAmount_data", betamount, "---", multiplebets, "---", wager, "maxpayoutdataaaa", maxpayout)
    let location = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
        getAllGames();
        getUserData()
        getGameSettings();
        getAllGameTransactionData()
    }, [accountInfo, location, wager, multiplebets, depositTokenBalance]);


    useEffect(() => {
        console.log("useEff depositTokenBalance", depositTokenBalance);
        getFeeInfoData();
    }, [wager, multiplebets, depositTokenBalance, feedata])


    const getAllGameTransactionData = async () => {
        try {
            let res = await getAllGameTransactionDetails();
            if (res?.data?.status == true) {
                setAllGameTransData(res?.data?.alltranscdata)
            }
        } catch (e) {
            console.log("getAllTransactionData_err", e)
        }
    }
    const getGameSettings = async () => {
        try {
            let res = await getGameSettingDetails();
            console.log('getGameSettings', res?.data)
            if (res?.data?.status == true) {
                let result = res?.data?.data[0];
                if (result?.Status == "Active") {
                    setGameSetting(result)
                }
            }
        } catch (e) {
            console.log("gameGameSettings_err", e)
        }
    }
    const getFeeInfoData = async () => {
        try {
            let res = await getFeeInfo();
            console.log("getfeedata", res?.data?.data, wager);
            console.log("111 useEff depositTokenBalance", depositTokenBalance)
            if (res?.data?.status == true) {
                let feedata = res?.data?.data[0]
                setFeeInfo(feedata);
                let toFixedtotalwager = (toFixedNumber(Number(depositTokenBalance)) * Number(wager) / 100) * multiplebets;
                console.log('toFixedtotalwager depositTokenBalance', toFixedNumber(Number(depositTokenBalance)), wager, toFixedNumber(Number(depositTokenBalance)) * Number(wager), toFixedtotalwager, toFixedtotalwager * multiplebets)
                let toFixedadmindepositclaim = toFixedNumber(Number(feedata?.DepositFee));
                let getclaimpercentage = Number(toFixedtotalwager) * Number(toFixedadmindepositclaim) / 100;
                setAdminClaimFee(getclaimpercentage)
                console.log('getclaimpercentage', feedata?.DepositFee, toFixedtotalwager, toFixedadmindepositclaim, getclaimpercentage, Number(toFixedtotalwager) + getclaimpercentage)
                let addclaimamount = Number(toFixedtotalwager) + getclaimpercentage;
                console.log("wager feee", depositTokenBalance, wager, multiplebets, (toFixedNumber(Number(depositTokenBalance)) * Number(wager) / 100) * multiplebets)
                console.log("getadmin feee", toFixedtotalwager, toFixedadmindepositclaim, getclaimpercentage, addclaimamount);
                console.log('add claimamount', addclaimamount, toFixedNumber(addclaimamount))
                setWagerClaimFee(addclaimamount)
            }

        } catch (e) {
            console.log("getFeeInfoData_err", e)
        }
    }

    const getUserData = async () => {
        try {
            let data = { walletAddress: accountInfo }
            let res = await getUserInfoHooks(data);
            console.log("getuserdatsgal", res?.data?.data);
            setUserDetails(res?.data?.data)
            if (res?.data?.status == true) {
                let userId = res?.data?.data?._id
                let getrefferdata = await getRefferInfo(userId);
                if (getrefferdata?.data?.status == true) {
                    console.log("refferInfo", getrefferdata?.data) // login refferdata
                    setRefferDetails(getrefferdata?.data?.data)
                }
            }
        } catch (e) {
            console.log("getUserData_err", e)
        }
    }

    useEffect(() => {
        async function fetchData() {
            if (pathName != undefined) {
                let reqdata = { filter: transfilter, walletAddress: accountInfo, gameName: pathName };
                console.log("asdfasdfasdfasdsddd sdfasdf", reqdata, pathName)
                let res = await getgametransdetails(reqdata);
                console.log('sdfgsthdfs', res?.data?.data)
                if (res?.data?.status == true) {
                    setGameTrans(res?.data?.data)
                }
            }
        }
        fetchData()

    }, [accountInfo, pathName, !check, check, transfilter]);

    useEffect(() => {
        async function fetchData() {
            console.log('wagerclaimfee_wagerclaimfee', wagerclaimfee, isApprovedAmount);
            let WINRToken = stakeTokens[0].address;
            let tokenInfo = await UseTokenInfo(WINRToken);
            console.log("isApprove amount data", isApprovedAmount);
            let amountdata = parseFloat(isApprovedAmount) / 10 ** parseInt(tokenInfo?.decimals);
            console.log('onChange checkall conditions', amountdata);
            let toFixedApproveAmount = "";
            if (amountdata > 1) {
                toFixedApproveAmount = convertScientificToWholeNumber(Number(amountdata));
            } else {
                toFixedApproveAmount = Number(amountdata);
            }
            console.log("useeff toFixedApproveAmount", toFixedApproveAmount, wagerclaimfee, Number(toFixedApproveAmount) <= wagerclaimfee);
            if (isApprovedAmount <= 0) {
                setWagErr("");
            } else {
                if (Number(toFixedApproveAmount) <= wagerclaimfee) {
                    console.log("toFixedApproveAmount_data", Number(toFixedApproveAmount))
                    setWagErr(`You have approve only ${Number(toFixedApproveAmount)?.toFixed(3)}`);
                    setIsApproved(false)
                } else {
                    setWagErr("");
                    setIsApproved(true);
                }
            }

        };
        fetchData();
    }, [wagerclaimfee, isApprovedAmount]);

    useEffect(() => {
        if (pathName != undefined) {
            if (pathName == "Wheel") {
                let conditiondata = getPredictionByName(pathName);
                let maxpay = 0;
                console.log("initialSelectedWheel", initialSelectedWheel)
                if (initialSelectedWheel != "") {
                    let selected = initialSelectedWheel == 1 ? 2 : initialSelectedWheel == 2 ? 3 : initialSelectedWheel == 3 ? 6 : initialSelectedWheel == 4 ? 48 : 48
                    maxpay = selected;
                    // what you choose the wheelx
                } else {
                    console.log("maxpayoutdata", conditiondata, maxpay)
                    for (let i = 0; i < conditiondata?.length; i++) {
                        console.log("conidtiondata", Object.keys(conditiondata[i])[0].split('x')[0], maxpay, Number(Object.keys(conditiondata[i])[0].split('x')[0]) > maxpay)
                        if (Number(Object.keys(conditiondata[i])[0].split('x')[0]) > maxpay) {
                            maxpay = Number(Object.keys(conditiondata[i])[0].split('x')[0]);
                        }
                    }
                    //default 48 x
                }
                console.log("maxpayoutdata", maxpay)
                setMaxPay(maxpay)
                let percentagemaxpayout = 0;
                gamepercentage && gamepercentage?.length > 0 && gamepercentage?.map((val) => {
                    console.log('Object.keys(val)', Object.keys(val), maxpay)
                    if (`${maxpay}x` == Object.keys(val)) {
                        percentagemaxpayout = Number(Object.values(val));
                    }
                })
                console.log("percentagemaxpayout", calculatewager, percentagemaxpayout)
                let rewardpercentage = Number(calculatewager) * Number(percentagemaxpayout) / 100;
                let reward = (Number(calculatewager) + Number(rewardpercentage)) * multiplebets
                setMaxPayout(reward)

            } else if (pathName == "Limbo") {
                let maxpay;
                console.log("pathName limbo", limbodragval, gamepercentage);
                maxpay = limbodragval;
                setMaxPay(maxpay)
                let percentagemaxpayout = 0;
                if (Number(maxpay) != 0) {
                    gamepercentage && gamepercentage?.length > 0 && gamepercentage?.map((val) => {
                        console.log("maxpaydatalimbo", maxpay, Object.keys(val)[0].split('x')[0], (Object.keys(val)[0].split('x')[1]).split('-')[1]);
                        if ((Number(Object.keys(val)[0].split('x')[0]) <= Number(maxpay)) && (Number((Object.keys(val)[0].split('x')[1]).split('-')[1]) >= Number(maxpay))) {
                            console.log("Object.values(val)", Object.values(val))
                            percentagemaxpayout = Object.values(val);
                        }
                    });
                } else {
                    gamepercentage && gamepercentage?.length > 0 && gamepercentage?.map((val) => {
                        console.log('val dasdfasfawer', percentagemaxpayout, val, Object.values(val));
                        if (Number(percentagemaxpayout) < Number(Object.values(val)[0])) {
                            percentagemaxpayout = Number(Object.values(val)[0])
                        }
                    })
                }
                console.log("limbo percentagemaxpayout", percentagemaxpayout)
                let rewardpercentage = Number(calculatewager) * Number(percentagemaxpayout) / 100;
                let reward = (Number(calculatewager) + Number(rewardpercentage)) * multiplebets;
                setMaxPayout(reward)
                console.log("percentagemaxpayout", calculatewager, percentagemaxpayout, rewardpercentage, reward, multiplebets)
            } else if (pathName == "Moon") {
                console.log('calculatewager_calculatewager', calculatewager, gamepercentage)
                // let percentagemaxpayout = 0;
                // gamepercentage && gamepercentage?.length > 0 && gamepercentage?.map((val) => {
                //     console.log('object_keys_data',val, calculatewager, Object.keys(val)[0].split('x')[0],(Object.keys(val)[0].split('x')[1]).split('-')[1])
                //     if ((Number(Object.keys(val)[0].split('x')[0]) < Number(calculatewager)) && (Number((Object.keys(val)[0].split('x')[1]).split('-')[1]) > Number(calculatewager))) {
                //         console.log("Object.values(val)", Object.values(val))
                //         percentagemaxpayout = Object.values(val);
                //     }
                // });


                let maxpay;
                let percentagemaxpayout = 0;
                console.log("maxpayoutdatamoongame", maxpay)
                gamepercentage && gamepercentage?.length > 0 && gamepercentage?.map((val) => {
                    console.log('val dasdfasfawer', percentagemaxpayout, val, Object.values(val))
                    console.log('sw5r4e35sfsd', Number(Object.values(val)[0]), Number(percentagemaxpayout), Number(percentagemaxpayout) < Number(Object.values(val)[0]))
                    if (Number(percentagemaxpayout) < Number(Object.values(val)[0])) {
                        percentagemaxpayout = Number(Object.values(val)[0])
                    }
                })
                console.log('percentagemaxpayout_percentagemaxpayout', percentagemaxpayout)
                let rewardpercentage = Number(calculatewager) * Number(percentagemaxpayout) / 100;
                let reward = (Number(calculatewager) + Number(rewardpercentage)) * multiplebets;
                console.log("Moon calculatewager_calculatewager", calculatewager, percentagemaxpayout, rewardpercentage, reward)
                setMaxPayout(reward);
            } else if (pathName == "Range") {
                let maxpay;
                console.log("pathName range", rangeval);
                maxpay = rangeval;
                setMaxPay(maxpay)

                let percentagemaxpayout = 0;
                gamepercentage && gamepercentage?.length > 0 && gamepercentage?.map((val) => {
                    console.log('val_datarange', maxpay, val, (Number(Object.keys(val)[0].split('x')[0]) <= Number(maxpay)), (Number((Object.keys(val)[0].split('x')[1]).split('-')[1]) >= Number(maxpay)))
                    if ((Number(Object.keys(val)[0].split('x')[0]) <= Number(maxpay)) && (Number((Object.keys(val)[0].split('x')[1]).split('-')[1]) >= Number(maxpay))) {
                        percentagemaxpayout = Number(Object.values(val));
                    }
                });
                let randomrangeval;
                // if (gamepercentage?.length > 0) {
                //     console.log('maxpay dsdfasdfasdfata', maxpay);
                //     if (maxpay == 5) {
                //         randomrangeval = Number(Object.values(gamepercentage[0])[0]);
                //     } else if (maxpay == 95) {
                //         randomrangeval = Number(Object.values(gamepercentage[gamepercentage?.length-1])[0]);
                //     } else {
                //         randomrangeval = getRandomRange(1, percentagemaxpayout)
                //     }
                // }


                if (gamepercentage?.length > 0) {
                    console.log('gamepercentagedata', gamepercentage, maxpay, percentagemaxpayout, checkrangeval, rangemultiplier)
                    if (checkcount == 0) {
                        if (maxpay == 5) {
                            randomrangeval = Number(Object.values(gamepercentage[0])[0]);
                        } else if (maxpay == 95) {
                            randomrangeval = Number(Object.values(gamepercentage[gamepercentage?.length - 1])[0]);
                        } else {
                            randomrangeval = getRandomRange(1, percentagemaxpayout)
                        }
                        setCheckCount(1);
                        setCheckRangeval(rangeval);
                    } else if (checkcount == 1) {
                        console.log("maxpay , checkrangeval, rangeval", maxpay, checkrangeval, rangeval)
                        if (maxpay != checkrangeval) {
                            if (maxpay == 5) {
                                randomrangeval = Number(Object.values(gamepercentage[0])[0]);
                            } else if (maxpay == 95) {
                                randomrangeval = Number(Object.values(gamepercentage[gamepercentage?.length - 1])[0]);
                            } else {
                                randomrangeval = getRandomRange(1, percentagemaxpayout)
                            }
                            setCheckRangeval(rangeval);
                        } else if (maxpay == checkrangeval) {
                            randomrangeval = rangemultiplier;
                        }
                    }
                }

                console.log('multiplier gamepercentagedata', maxpay, randomrangeval)
                // if(maxpay)
                console.log('randomrangevaldata', maxpay, randomrangeval, percentagemaxpayout);
                console.log('11 checka all rangmul', randomrangeval)
                setRangeMultiplier(randomrangeval)
                console.log("percentagemaxpayout_percentagemaxpayout", percentagemaxpayout);
                let rewardpercentage = Number(calculatewager) * Number(randomrangeval) / 100;
                let reward = (Number(calculatewager) + Number(rewardpercentage)) * multiplebets
                console.log("maxpayout_maxpayout", calculatewager, reward, rewardpercentage);
                setMaxPayout(reward)
            } else {
                // getPredictionByName
                let conditiondata = getPredictionByName(pathName);
                console.log("conditiondata", conditiondata)
                let maxpay = 0;
                for (let i = 0; i < conditiondata?.length > 0; i++) {
                    console.log("conidtiondata", Object.keys(conditiondata[i])[0].split('x')[0], maxpay, Number(Object.keys(conditiondata[i])[0].split('x')[0]) > maxpay)
                    if (Number(Object.keys(conditiondata[i])[0].split('x')[0]) > maxpay) {
                        maxpay = Number(Object.keys(conditiondata[i])[0].split('x')[0]);
                    }
                }
                console.log("useEffmaxpay", maxpay, gamepercentage)
                setMaxPay(maxpay)
                let percentagemaxpayout = 0;
                gamepercentage && gamepercentage?.length > 0 && gamepercentage?.map((val) => {
                    if (`${maxpay}x` == Object.keys(val)) {
                        percentagemaxpayout = Object.values(val);
                    }
                });
                console.log("percentagemaxpayout_data", percentagemaxpayout, calculatewager);
                let rewardpercentage = Number(calculatewager) * Number(percentagemaxpayout) / 100;
                let reward = (Number(calculatewager) + Number(rewardpercentage)) * multiplebets
                setMaxPayout(reward)
                console.log("percentagemaxpayout", calculatewager, percentagemaxpayout, rewardpercentage, reward, multiplebets)
            }
        }
    }, [accountInfo, pathName, multiplebets, initialSelectedWheel, limbodragval, rangeval, calculatewager])


    // let randomrangeval = 0;
    // if(gamepercentage?.length > 0) {
    //     if(maxpay == Number(Object.values(gamepercentage[0])[0])) {
    //      randomrangeval = maxpay
    //     } else if (maxpay == Number(Object.values(gamepercentage[gamepercentage?.length - 1])[0])) {
    //      randomrangeval = maxpay
    //     } else {
    //      randomrangeval =  getRandomRange(1, percentagemaxpayout)
    //     }
    // }
    function getRandomRange(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }


    const getAllGames = async () => {

        try {
            let res = await getAllGamesInfoHooks();
            console.log("getallgamesInfoHooks", res?.data?.data);
            if (res?.status == 200) {
                if (res?.data?.data) {
                    setGameCard(res?.data?.data)
                }
            }
        } catch (e) {
            console.log("getAllGames_err", e)
        }
    }

    const getData = async () => {
        try {
            let pathname = location.pathname.split('/')[2];
            console.log("PathName gamepath", pathname)
            if (pathname != undefined) {
                setPathName(pathname)
                let data = {
                    gameName: pathname
                }
                let res = await getgamesInfoHooks(data);
                console.log("getgamesInfoHooks 2222 keke", res);
                if (res?.status == 200) {
                    if (res?.data?.data) {
                        setGameInfo(res?.data?.data)
                        setGamePercentage(res?.data?.data?.GamePercentage)
                    }
                }
            }
        } catch (e) {
            console.log("getData_err", e)
        }
    }



    useEffect(() => {
        checkIsApproval();
        const walletAddress = getAccount();
        console.log("walletAddress_walletAddress", walletAddress)
        setAccountInfo(walletAddress);
        getUserBetTokenBalance();
    }, [accountInfo, !start, start, location, !tossstart, tossstart, plinkostart, !plinkostart, rpcstart, !rpcstart, !wheelstart, wheelstart, limbostart, !limbostart, moonstart, !moonstart, rangestart, !rangestart, status]);


    useEffect(() => {

        (async () => {
            let walletAddress = await getAccount();
            console.log("walletAddress != null", walletAddress != null)
            // if (walletAddress != null) {
            let transc = await getTransactionDetails(walletAddress, pathName);
            console.log("transc details check", transc, transc?.oneweekdata, transc?.transcdata?.length);
            if (transc?.transcdata?.length > 0) {
                // That is useless state , just store all transactiondata.
                setTransactionInfo(transc?.transcdata);
                setCheck(true);
            } else {
                setCheck(true);
            }
            if (transc?.oneweekdata?.length > 0) {
                OneWeekWonInfo(transc?.oneweekdata)
            }
            if (transc?.onemonthdata?.length > 0) {
                OneMonthWonInfo(transc?.onemonthdata)
            }
        })();
        console.log("call this function ")
    }, [accountInfo, !check, location]);

    let a = [(1 + 1), (1 + 2), (1 + 3), (1 + 4), (1 + 5), (1 + 6),
    (2 + 1), (2 + 2), (2 + 3), (2 + 4), (2 + 5), (2 + 6),
    (3 + 1), (3 + 2), (3 + 3), (3 + 4), (3 + 5), (3 + 6),
    (4 + 1), (4 + 2), (4 + 3), (4 + 4), (4 + 5), (4 + 6),
    (5 + 1), (5 + 2), (5 + 3), (5 + 4), (5 + 5), (5 + 6),
    (6 + 1), (6 + 2), (6 + 3), (6 + 4), (6 + 5), (6 + 6)];

    useEffect(() => {
        let sum = 0;
        for (let i = 0; i < selected.length; i++) {
            sum = Number(sum) + Number(selected[i]);
        }
        let count = 0;
        if (selected?.length == 2) {
            a.map((val) => {
                console.log("val_Data", val)
                if (val == sum) {
                    count++;
                }
            })
        }
        let winchance = (count / 36).toFixed(2);
        console.log("Sum Dice Num", sum, selected, count, winchance);
        setWinChance(winchance)
    }, [selected, location]);


    useEffect(() => {
        if (accountInfo != '' && accountInfo != null) {
            getOrderData();
            console.log("gamedata_gamedata 345 k1k1 ruru", start, location, limbostart, rangestart);
        }
    }, [accountInfo, !start, start, checkOrder, pathName, location, limbostart, !limbostart, plinkostart, !plinkostart, tossstart, !tossstart, rpcstart, !rpcstart, wheelstart, !wheelstart, !moonstart, moonstart, rangestart, !rangestart, depositTokenBalance]);

    const getOrderData = async () => {
        try {
            if (accountInfo != '' && accountInfo != null) {
                let pathname = location.pathname.split('/')[2];
                console.log("pathName ruru", pathname)

                let data = { walletAddress: accountInfo, gameName: pathname }
                let res = await getUserInfoHooks(data);
                let Orderres = await getOrderDetailsHooks(data) // get user order data 
                let orderdata = Orderres?.data?.data;
                console.log("orderdata createrescreateres ruru", orderdata, Object.values(orderdata)?.length > 0)
                if (res?.status == 200) {

                    if (Object.values(orderdata)?.length > 0) {
                        let reqdata = {
                            walletAddress: accountInfo,
                            orderId: orderdata?._id
                        }
                        let resordertransc = await getOrderTransactionDetailsHooks(reqdata);
                        let ordertranscdata = resordertransc?.data?.data;
                        console.log("ordertransdata_detals ruru", ordertranscdata, ordertranscdata?.length, ordertranscdata?.length > 0);
                        let sumgain = 0;
                        let sumloss = 0;
                        // stopgain , stoploss amount add loop
                        if (ordertranscdata?.length > 0) {
                            console.log("stopgain , stoploss amount add loop gamedetails lele")
                            ordertranscdata && ordertranscdata?.map((el, i) => {
                                if (el?.OrderId?.StopGain != 0 || el?.OrderId?.StopLoss != 0) {
                                    console.log("el?.Multiplier?.split('x')[0]", el?.Multiplier?.split('x')[0] == "0", el?.MaxPayOut, 0 + Number(Number(el?.MaxPayOut).toFixed(4)))
                                    if (el?.Multiplier?.split('x')[0] == "0") {
                                        console.log("dataaaaaaaaaa", el?.MaxPayOut)
                                        if (el?.OrderId?.StopLoss != 0) {
                                            sumloss = Number(sumloss) + Number(Number(el?.MaxPayOut).toFixed(4));
                                        }
                                    } else {
                                        console.log("dataaaaaaaaaa1 gain", el?.MaxPayOut)
                                        if (el?.OrderId?.StopGain != 0) {
                                            sumgain = Number(sumgain) + Number(Number(el?.MaxPayOut).toFixed(4));
                                        }
                                    }
                                }
                            })
                        }
                        console.log("sum_sum1", sumgain, '----', sumloss);
                        //orderdata uploaded & play that guy (transaction data upload)
                        if (ordertranscdata?.length > 0) {
                            console.log("transaction data upload gamedetails lele")
                            ordertranscdata && ordertranscdata?.map(async (el, i) => {
                                console.log("eeeeeeeeeeeeee", el, Number(el?.MaxPayOut).toFixed(4));
                                // transactional data upload check stopgain & stoploss included
                                if (el?.OrderId?.StopGain == 0 && el?.OrderId?.StopLoss == 0) {
                                    console.log("transactional data upload check stopgain & stoploss included gamedetails lele", orderdata.RemainingBets)
                                    // console.log("orderdata_orderdata", orderdata.RemainingBets,orderdata?.RemainingBets != 0 && orderdata?.BetStatus == false, orderdata)
                                    if (orderdata?.RemainingBets != 0 && orderdata?.BetStatus == false) {
                                        setWager(orderdata.Wager);
                                        setCalculateWager(orderdata.CalculateWager)
                                        setMultipleBets(orderdata.MultipleBets);
                                        setRemainingBets(orderdata.RemainingBets)
                                        setBetAmount(orderdata.BetAmount);
                                        setSelected([orderdata.FirstDice, orderdata.SecondDice]);
                                        setSelectedCoin(orderdata?.SelectedCoin)
                                        setSelectedRpc(orderdata?.SelectedRpc)
                                        setSelectedWheel(orderdata?.SelectedWheel)
                                        setSelectedLimbo(orderdata?.SelectedLimbo)
                                        setPlay(true)
                                        setBet(true)
                                        setDisabledtossBtn(true)
                                        setDisabledRpcBtn(true)
                                        setDisabledWheelBtn(true)
                                        setDisabledLimboBtn(true)
                                        setDisabledMoonBtn(true)
                                        setDisabledRangeBtn(true)
                                        setDisabledPlinkoBtn(true)
                                        setStopGain(orderdata?.StopGain)
                                        setStopLoss(orderdata?.StopLoss)
                                        setMaxPayout(orderdata?.MaxPayOut)
                                        setWagerClaimFee(orderdata?.TotalWager)
                                        setUserGameDetails(orderdata)
                                        setSelectedRange(orderdata?.selectedRange)
                                        setRangeArray(orderdata?.RangeArray)
                                        setRangeMultiplier(orderdata?.RangeMultiplier)
                                        setPlinkoBets(orderdata?.PlinkoBets)
                                        let selecteddata = [orderdata.FirstDice, orderdata.SecondDice];
                                        console.log('22 checka all rangmul', orderdata?.RangeMultiplier)
                                        let sum = 0;
                                        for (let i = 0; i < selecteddata.length; i++) {
                                            sum = Number(sum) + Number(selecteddata[i]);
                                        }
                                        setPrediction(sum);
                                    } else {
                                        setBet(false)
                                        setDisabledtossBtn(false)
                                        setDisabledRpcBtn(false)
                                        setDisabledWheelBtn(true)
                                        setDisabledLimboBtn(true)
                                        setDisabledMoonBtn(true)
                                        setDisabledRangeBtn(true)
                                        setDisabledPlinkoBtn(true)
                                    }
                                } else {
                                    // stopgain & stoploss upload (point of amount will come) check the calculation
                                    //That calculation condition is correct enter this if condition  
                                    console.log("stopgain & stoploss upload gamedetails lele", el?.TotalWager, el?.OrderId?.StopGain, sumgain, sumloss, ((Number(el?.TotalWager) * (el?.OrderId?.StopGain)) / 100) <= (sumgain), ((Number(el?.TotalWager) * (el?.OrderId?.StopLoss)) / 100) <= (sumloss), ((Number(el?.TotalWager) * (el?.OrderId?.StopGain)) / 100) <= (sumgain) || ((Number(el?.TotalWager) * (el?.OrderId?.StopLoss)) / 100) <= (sumloss))
                                    if ((((Number(el?.OrderId?.StopGain) != 0) && ((Number(el?.TotalWager) * (el?.OrderId?.StopGain)) / 100) <= (sumgain)) || ((Number(el?.OrderId?.StopLoss) != 0) && ((Number(el?.TotalWager) * (el?.OrderId?.StopLoss)) / 100) <= (sumloss)))) {
                                        console.log("That calculation condition is correct gamedetails lele")
                                        let data = {
                                            walletAddress: accountInfo,
                                            orderId: orderdata?._id
                                        }
                                        let res = await UpdateOrderDetailsHooks(data);
                                        console.log("updateorder_Data", res?.data?.data);
                                        if (res?.data?.data?.BetStatus == true) {
                                            getOrderData()
                                        }
                                        let orderdata1 = res?.data?.data;
                                        setWager(0.0001);
                                        let percentagecalculate = depositTokenBalance * 0.0001 / 100;
                                        setCalculateWager(percentagecalculate)
                                        setMultipleBets(0); // Because multiplebtes - reaminingbet + 1 ==>coinflip
                                        setRemainingBets(0);
                                        setBetAmount(1);
                                        setSelected([]);
                                        setSelectedCoin('')
                                        setSelectedRpc('')
                                        setSelectedWheel(0)
                                        setSelectedLimbo(0)
                                        setRangeArray([])
                                        setRangeMultiplier(rangemultiplier)
                                        setPlay(false)
                                        setBet(false)
                                        setDisabledtossBtn(false)
                                        setDisabledRpcBtn(false)
                                        setDisabledWheelBtn(false);
                                        setDisabledLimboBtn(false)
                                        setDisabledMoonBtn(false)
                                        setDisabledRangeBtn(false)
                                        setDisabledPlinkoBtn(false)
                                        setStopGain(0)
                                        setStopLoss(0)
                                        setUserGameDetails({});
                                        setPrediction(0)
                                        setMaxPayout(0)
                                        setWagerClaimFee(0)
                                        setSelectedRange('')
                                        setPlinkoBets(12)
                                        console.log('33 checka all rangmul', rangemultiplier)
                                    } else {
                                        // calculation condition fail
                                        console.log("orderdata?.RemainingBets", orderdata?.RemainingBets)
                                        console.log("That calculation condition is wrong gamedetails lele")
                                        if (orderdata?.RemainingBets != 0 && orderdata?.BetStatus == false) {
                                            setWager(orderdata.Wager);
                                            setCalculateWager(orderdata.CalculateWager)
                                            setMultipleBets(orderdata.MultipleBets);
                                            setRemainingBets(orderdata.RemainingBets);
                                            setBetAmount(orderdata.BetAmount);
                                            setSelected([orderdata.FirstDice, orderdata.SecondDice]);
                                            setSelectedCoin(orderdata?.SelectedCoin)
                                            setSelectedRpc(orderdata?.SelectedRpc)
                                            setSelectedWheel(orderdata?.SelectedWheel)
                                            setSelectedLimbo(orderdata?.SelectedLimbo)
                                            setRangeArray(orderdata?.RangeArray)
                                            setRangeMultiplier(orderdata?.RangeMultiplier)
                                            setPlay(true) // dice game
                                            setBet(true)
                                            setDisabledtossBtn(true)
                                            setDisabledRpcBtn(true)
                                            setDisabledWheelBtn(true)
                                            setDisabledLimboBtn(true)
                                            setDisabledMoonBtn(true) // false
                                            setDisabledRangeBtn(true)
                                            setDisabledPlinkoBtn(true)
                                            setStopGain(orderdata?.StopGain)
                                            setStopLoss(orderdata?.StopLoss)
                                            setUserGameDetails(orderdata)
                                            setMaxPayout(orderdata?.MaxPayOut)
                                            setWagerClaimFee(orderdata?.TotalWager)
                                            setSelectedRange(orderdata?.selectedRange)
                                            setPlinkoBets(orderdata?.PlinkoBets)
                                            let selecteddata = [orderdata.FirstDice, orderdata.SecondDice];
                                            let sum = 0;
                                            for (let i = 0; i < selecteddata.length; i++) {
                                                sum = Number(sum) + Number(selecteddata[i]);
                                            }
                                            setPrediction(sum);
                                            console.log('44 checka all rangmul', orderdata?.RangeMultiplier)
                                        } else {
                                            setBet(false)
                                            setDisabledtossBtn(false)
                                            setDisabledRpcBtn(false)
                                            setDisabledWheelBtn(false)
                                            setDisabledLimboBtn(false)
                                            setDisabledMoonBtn(false)
                                            setDisabledRangeBtn(false)
                                            setDisabledPlinkoBtn(false)
                                        }
                                    }
                                }
                            })
                        } else {
                            // orderdata --> Ordered upload but is not transactional(Bet upload they didn't play)
                            console.log("Bet upload they didn't play gamedetails lele", orderdata)
                            if (Number(orderdata?.RemainingBets) != 0 && orderdata?.BetStatus == false) {
                                setWager(orderdata.Wager);
                                setCalculateWager(orderdata.CalculateWager)
                                setMultipleBets(orderdata.MultipleBets);
                                setRemainingBets(orderdata.RemainingBets);
                                setBetAmount(orderdata.BetAmount);
                                setSelected([orderdata.FirstDice, orderdata.SecondDice]);
                                setSelectedCoin(orderdata?.SelectedCoin)
                                setSelectedRpc(orderdata?.SelectedRpc)
                                setSelectedWheel(orderdata?.SelectedWheel)
                                setSelectedLimbo(orderdata?.SelectedLimbo)
                                setRangeArray(orderdata?.RangeArray)
                                setRangeMultiplier(orderdata?.RangeMultiplier)
                                setPlay(true)
                                setBet(true)
                                setDisabledtossBtn(true)
                                setDisabledRpcBtn(true)
                                setDisabledWheelBtn(true)
                                setDisabledLimboBtn(true)
                                setDisabledMoonBtn(true)
                                setDisabledRangeBtn(true)
                                setDisabledPlinkoBtn(true)
                                setUserGameDetails(orderdata)
                                setStopGain(orderdata?.StopGain)
                                setStopLoss(orderdata?.StopLoss)
                                setSelectedRange(orderdata?.selectedRange)
                                setPlinkoBets(orderdata?.PlinkoBets)
                                let selecteddata = [orderdata.FirstDice, orderdata.SecondDice];
                                let sum = 0;
                                for (let i = 0; i < selecteddata.length; i++) {
                                    sum = Number(sum) + Number(selecteddata[i]);
                                }
                                setPrediction(sum);
                                setMaxPayout(orderdata?.MaxPayOut)
                                setWagerClaimFee(orderdata?.TotalWager)
                                console.log('55 checka all rangmul', orderdata?.RangeMultiplier)
                            } else {
                                setBet(false)
                                setDisabledtossBtn(false)
                                setDisabledRpcBtn(false)
                                setDisabledWheelBtn(false)
                                setDisabledLimboBtn(false)
                                setDisabledMoonBtn(false)
                                setDisabledRangeBtn(false)
                                setDisabledPlinkoBtn(false)
                            }
                        }
                    } else {
                        console.log("Initial" + pathName + "No orderdata gamepath gamedetails lele")
                        if (pathName == 'Moon') {
                            setWager(1);
                            let percentagecalculate = parseInt(depositTokenBalance * 1) / 100;
                            setCalculateWager(percentagecalculate)
                        } else {
                            setWager(0.0001);
                            let percentagecalculate = depositTokenBalance * 0.0001 / 100;
                            console.log('percentagecalculate_percentagecalculate', depositTokenBalance, depositTokenBalance * 0.0001 / 100, percentagecalculate)
                            setCalculateWager(percentagecalculate)
                        }

                        setMultipleBets(1);
                        setRemainingBets(0);
                        setBetAmount(1);
                        setSelected([]);
                        setSelectedCoin('')
                        setSelectedRpc('')
                        setSelectedWheel(0)
                        setSelectedLimbo(0)
                        setRangeArray([])
                        // setRangeMultiplier(rangemultiplier)
                        setPlay(false)
                        setBet(false);
                        setDisabledtossBtn(false)
                        setDisabledRpcBtn(false)
                        setDisabledWheelBtn(false)
                        setDisabledLimboBtn(false)
                        setDisabledMoonBtn(false)
                        setDisabledRangeBtn(false)
                        setDisabledPlinkoBtn(false)
                        setUserGameDetails({});
                        setStopGain(0)
                        setStopLoss(0);
                        setPrediction(0);
                        setStart(true)
                        setWheelStart(true)
                        setLimboStart(true)
                        setMoonStart(true)
                        setRangeStart(true)
                        setSelectedRange('')
                        setPlinkoBets(12)
                        // setMaxPayout(0)


                    }
                }
            }
        } catch (e) {
            console.log("getUserData_err", e)
        }
    }

    const OneMonthWonInfo = async (transc) => {
        try {
            if (pathName != undefined) {
                console.log("OneMonthWonInfo gamedata", pathName, transc)
                let res = await GamePrediction([], transc, calculatewager, multiplebets, 0, pathName, gamesetting, allgametransacdata);
                console.log("OneMonthWonInfo gamedata", res)
                setOneMonthWonInfo(res)
            }
        } catch (e) {
            console.log("PredictionInfo_err", e)
        }
    }

    const OneWeekWonInfo = async (transc) => {
        try {
            console.log("tarasdfasdfa", transc, pathName)
            if (pathName != undefined) {
                let res = await GamePrediction([], transc, calculatewager, multiplebets, 0, pathName, gamesetting, allgametransacdata);
                console.log("OneWeekWOnINFo gamedata" + pathName, res)
                setOneWeekWonInfo(res)
            }
        } catch (err) {
            console.log("OneWeekWonInfo_err", err)
        }
    }

    const getTransactionDetails = async (address, pathName) => {
        let data = {
            walletAddress: address,
            gameName: pathName
        }
        let res = await getGameAllTransactionInfoHooks(data);
        if (res?.status == 200) {
            if (res?.data?.alltranscdata?.length > 0) {
                setTransactionInfo(res?.data?.alltranscdata)
            }
        }
        console.log("res data transccc", res?.data, res?.data?.oneweekdata, res?.data?.onemonthdata)
        if (res?.data?.oneweekdata?.length > 0 || res?.data?.onemonthdata?.length > 0) {
            return {
                oneweekdata: res?.data?.oneweekdata?.length > 0 ? res?.data?.oneweekdata : [],
                onemonthdata: res?.data?.onemonthdata?.length > 0 ? res?.data?.onemonthdata : [],
                transcdata: res?.data?.transcdata?.length > 0 ? res?.data?.transcdata : [],
            }
        } else {
            return {
                oneweekdata: [],
                onemonthdata: [],
                transcdata: []
            }
        }

    }

    const getUserBetTokenBalance = async () => {
        try {
            let account = getAccount();
            console.log('accountconnect', stakeTokens[0]?.address, account);
            if (account != null) {
                const tokenBalance = account != null ? await getUserBalance(stakeTokens[0]?.address, account) : "";
                console.log("token_Balance", account != null, tokenBalance, Number(tokenBalance))
                setDepositTokenBalance(Number(tokenBalance))
            }

        } catch (err) {
            console.log("getUserBetTokenBalance_err")
        }

    }

    const checkIsApproval = async () => {
        console.log("APPROVAL...selectStakeToken ", stakeTokens[0]?.address);
        const approval = await checkIsApprovedGame(getAccount(), stakeTokens[0]?.address);
        console.log("checkIsApprovedGame...", approval);
        setIsApproved(approval?.allowstatus);
        setIsApprovedAmount(approval?.allowamount);
    }


    const approve = async () => {
        try {
            // await UseUserBet(,stakeTokens[0]?.address,"2x", "6x")
            if (accountInfo != null) {
                //a
                console.log("accountInfo_approve", accountInfo)
                let approve = await approveGame(accountInfo, stakeTokens[0]?.address);
                console.log("enter this useuserbet", approve)
                if (approve) {
                    setIsApproved(true);
                    console.log("enter this one123123")
                    setStatus(!status)
                }

            } else {
                alert("CONNECTWALLET PLEASE !!!");
            }
        } catch (err) {
            console.log("approve_err", err)
        }
    }

    const Bet = async () => {
        try {
            let min = 0;
            let max = 0;
            gameCard && gameCard?.length > 0 && gameCard?.map((val, i) => {
                console.log("val_datassss", val?.GameName, val?.GameName == pathName, val?.BetPercentage[0]);
                if (val?.GameName == pathName) {
                    min = val?.BetPercentage[0]['Min'];
                    max = val?.BetPercentage[1]['Max'];
                }
            });
            let WINRToken = stakeTokens[0].address;
            let tokenInfo = await UseTokenInfo(WINRToken);
            let amountdata = parseFloat(isApprovedAmount) / 10 ** parseInt(tokenInfo.decimals);
            console.log('checkall conditions', isApprovedAmount, tokenInfo?.decimals, amountdata);
            let toFixedApproveAmount = ""
            if (amountdata > 1) {
                toFixedApproveAmount = convertScientificToWholeNumber(Number(amountdata));
            } else {
                toFixedApproveAmount = amountdata
            }

            let percentageamount = ((depositTokenBalance * wager) / 100) * multiplebets;
            console.log('checkall conditions', pathName, isApprovedAmount, tokenInfo?.decimals, amountdata, toFixedApproveAmount);
            if (pathName == "Dice") {
                console.log("depositTokenBalance_wagerc", depositTokenBalance, wagerclaimfee, depositTokenBalance >= wagerclaimfee)
                if (depositTokenBalance >= wagerclaimfee) {
                    if (wagerclaimfee > 0 && wagerr == "") {
                        console.log("toFixedAmount_wagerclaimfee", toFixedApproveAmount, wagerclaimfee, Number(toFixedApproveAmount) >= wagerclaimfee)
                        if (Number(toFixedApproveAmount) >= wagerclaimfee) {
                            if (selected?.length >= 2) {
                                console.log("isApprovedAmount", isApprovedAmount);
                                // let amountdata = isApprovedAmount 
                                console.log("min err", min, max, percentageamount, percentageamount < Number(min), "max err", Number(max) < percentageamount);
                                if (!(percentageamount < Number(min)) && !(Number(max) < percentageamount)) {
                                    console.log('selected', selected?.length)
                                    let amount = Number(wagerclaimfee);
                                    console.log('amountclaimdata', amount, wagerclaimfee)
                                    const decimal = stakeTokens[0]?.decimal;
                                    const convertedAmount = getWholeNumber(amount, decimal);
                                    setBet(true)
                                    // setDisabledtossBtn(false)
                                    let address = await getAccount();
                                    console.log("address_address mmm", address);
                                    let Id = await ToastBet("", "loading")
                                    console.log("iasdfasdfasd", Id)
                                    let toFixed = toFixedNumber(parseInt(convertedAmount));
                                    let commissionfeetoFixed = toFixedNumber(adminclaimfee);
                                    console.log("asdfasdfsasdf", adminclaimfee, parseInt(adminclaimfee), commissionfeetoFixed)
                                    console.log('amount iasdfasdfasd', multiplebets, wager, parseInt(convertedAmount), toFixed)
                                    console.log('Dice toFixed_amount', wagerclaimfee, convertedAmount, toFixed)
                                    let betdata = await UseUserBet(String(toFixed), stakeTokens[0]?.address, "2", "6", address);
                                    console.log("betdata_betdata", betdata)
                                    if (betdata != "rejected" && betdata != "something wrong !!!") {
                                        if (betdata != undefined) {
                                            await ToastBet(Id, betdata);
                                            let reqData = {
                                                walletAddress: accountInfo, userId: userdetails?._id, parentId: refferdetails?.parentId, gameName: pathName,
                                                amount: (calculatewager * multiplebets), commissionfee: commissionfeetoFixed, refferalfee: 0, totalamount: wagerclaimfee, status: 'Completed', type: 'Deposit',
                                                transtype: "Bet"
                                            }
                                            console.log("reqdatasdfasdf", reqData)
                                            let createorderres = await createOrderdetails(pathName, wager, multiplebets, betamount, selected, accountInfo, stopgain, stoploss, 0, 0, maxpayout, calculatewager, wagerclaimfee, '', '')
                                            let createTrans = await createTransactionHooks(reqData)
                                            let updatedata = { commissionfee: commissionfeetoFixed }
                                            let updateCommissionFee = await updateAdminCommissionDetails(updatedata)
                                            console.log("rssssssssssssss", createorderres, createTrans, updateCommissionFee)
                                            if (createorderres?.status == 200 && createTrans?.status == 200) {
                                                if (createorderres?.data?.data) {
                                                    setStart(false)
                                                }
                                            }
                                        } else {
                                            await ToastBet(Id, betdata);
                                            setBet(false)
                                            setDisabledtossBtn(false)
                                        }

                                    } else {
                                        console.log("ToastBet_ToastBet", betdata)
                                        await ToastBet(Id, betdata);
                                        setBet(false);
                                        // setDisabledtossBtn(false)
                                    }
                                } else if ((percentageamount < Number(min)) || (Number(max) < percentageamount)) {
                                    if (percentageamount < Number(min)) {
                                        setWagErr(`Please choose atleast minimum ${min} HBLU`)
                                    } else if (Number(max) < percentageamount) {
                                        setWagErr(`$Maximum allowed ${max} HBLU`)
                                    }

                                } else {
                                    setWagErr("")
                                }
                            } else {
                                setBet(false);
                                // setDisabledtossBtn(false);
                                await toast.error(`Please Choose Your 2 Prediction Dices`,
                                    // {
                                    //     duration: 4000, style: { minWidth: '250px', minHeight: '55px' },
                                    // }
                                    {
                                        duration: 4000,
                                        position: position.position,
                                        style: style,
                                        iconTheme: iconTheme
                                    }
                                );

                            }

                        } else {
                            setIsApproved(false);
                            await Toast("error", `You have approve only ${toFixedApproveAmount} HBLU`);
                        }

                        //even
                    } else {
                        console.log("enter this condition now", wager, wager == 0)
                        if (wager == 0) {
                            await toast.error(`Please choose above 0.0001 wager`,
                                {
                                    duration: 4000,
                                    position: position.position,
                                    style: style,
                                    iconTheme: iconTheme
                                });
                        } else if (multiplebets == 0) {
                            await toast.error(`Please choose atleast 1 bet`,
                                {
                                    duration: 4000,
                                    position: position.position,
                                    style: style,
                                    iconTheme: iconTheme
                                });
                        }

                    }

                } else {
                    await Toast("error", "You don't have enough balance")
                }

            } else if (pathName == "CoinFlip") {
                if (depositTokenBalance >= wagerclaimfee) {
                    if (wagerclaimfee > 0 && wagerr == "") {
                        if (Number(toFixedApproveAmount) >= wagerclaimfee) {
                            if (!(percentageamount < Number(min)) && !(Number(max) < percentageamount)) {
                                console.log('intially amountcoinflip', wagerclaimfee)
                                let amount = Number(wagerclaimfee);
                                console.log("amountcoinflip", multiplebets, wager, amount)
                                const decimal = stakeTokens[0]?.decimal;
                                let tofixed = Number(amount).toFixed(4);
                                console.log("tofxiex", amount, tofixed)
                                const convertedAmount = getWholeNumber(amount, decimal);

                                setBet(true)
                                setDisabledtossBtn(false)
                                let address = await getAccount();
                                console.log("address_address mmm", address);
                                let Id = await ToastBet("", "loading")
                                let toFixed = toFixedNumber(parseInt(convertedAmount));
                                console.log('CoinFlip toFixed_amount', wagerclaimfee, convertedAmount, toFixed)
                                let commissionfeetoFixed = toFixedNumber(adminclaimfee);
                                let betdata = await UseUserBet(String(toFixed), stakeTokens[0]?.address, "2", "6", address);
                                console.log("betdata_betdata", betdata, intialselectedcoin)
                                if (betdata != "rejected" && betdata != "something wrong !!!" && betdata != undefined) {
                                    await ToastBet(Id, betdata);
                                    let reqData = {
                                        walletAddress: accountInfo, userId: userdetails?._id, parentId: refferdetails?.parentId, gameName: pathName,
                                        amount: (calculatewager * multiplebets), commissionfee: commissionfeetoFixed, refferalfee: 0, totalamount: wagerclaimfee, status: 'Completed', type: 'Deposit',
                                        transtype: "Bet"
                                    };
                                    console.log('reqDatasgal', reqData, calculatewager)
                                    let createorderres = await createOrderdetails(pathName, wager, multiplebets, betamount, intialselectedcoin, accountInfo, stopgain, stoploss, 0, 0, maxpayout, calculatewager, wagerclaimfee, '', '')
                                    let createTrans = await createTransactionHooks(reqData)
                                    let updatedata = { commissionfee: commissionfeetoFixed }
                                    let updateCommissionFee = await updateAdminCommissionDetails(updatedata)
                                    console.log("rssssssssssssss", createorderres, updateCommissionFee)
                                    if (createorderres?.status == 200 && createTrans?.status == 200) {
                                        if (createorderres?.data?.data) {
                                            setTossStart(false)
                                        }
                                    }
                                } else {
                                    await ToastBet(Id, betdata);
                                    setBet(false)
                                    // setDisabledtossBtn(false)
                                }
                            } else if ((percentageamount < Number(min)) || (Number(max) < percentageamount)) {
                                if (percentageamount < Number(min)) {
                                    setWagErr(`Please choose atleast minimum ${min} HBLU`)
                                } else if (Number(max) < percentageamount) {
                                    // Value must be between 6 and 12
                                    setWagErr(`$Maximum allowed ${max} HBLU`)
                                }

                            } else {
                                setWagErr("")
                            }
                        } else {
                            await Toast("error", `You have approve only ${toFixedApproveAmount} HBLU`);
                            setIsApproved(false);
                        }

                        //even
                    } else {
                        if (wager == 0) {
                            await toast.error(`Please choose above 0.0001 wager`,
                                {
                                    duration: 4000,
                                    position: position.position,
                                    style: style,
                                    iconTheme: iconTheme
                                });
                        } else if (multiplebets == 0) {
                            await toast.error(`Please choose atleast 1 bet`,
                                {
                                    duration: 4000,
                                    position: position.position,
                                    style: style,
                                    iconTheme: iconTheme
                                });
                        }

                    }

                } else {
                    await Toast("error", "You don't have enough balance")
                }

            } else if (pathName == "RPC") {

                if (depositTokenBalance >= wagerclaimfee) {
                    if (wagerclaimfee > 0 && wagerr == "") {
                        if (Number(toFixedApproveAmount) >= wagerclaimfee) {
                            if (!(percentageamount < Number(min)) && !(Number(max) < percentageamount)) {
                                let amount = Number(wagerclaimfee);
                                const decimal = stakeTokens[0]?.decimal;
                                const convertedAmount = getWholeNumber(amount, decimal);
                                setBet(true)
                                setDisabledRpcBtn(false)
                                let address = await getAccount();
                                console.log("address_address mmm", address);
                                let commissionfeetoFixed = toFixedNumber(adminclaimfee);
                                let Id = await ToastBet("", "loading")
                                let toFixed = toFixedNumber(parseInt(convertedAmount));
                                console.log('RPC toFixed_amount', wagerclaimfee, convertedAmount, toFixed)
                                let betdata = await UseUserBet(String(toFixed), stakeTokens[0]?.address, "2", "6", address);
                                console.log("betdata_betdata", betdata, initialSelectedRpc)
                                if (betdata != "rejected" && betdata != "something wrong !!!" && betdata != undefined) {
                                    await ToastBet(Id, betdata);
                                    let reqData = {
                                        walletAddress: accountInfo, userId: userdetails?._id, parentId: refferdetails?.parentId, gameName: pathName,
                                        amount: (calculatewager * multiplebets), commissionfee: commissionfeetoFixed, refferalfee: 0, totalamount: wagerclaimfee, status: 'Completed', type: 'Deposit',
                                        transtype: "Bet"
                                    }
                                    let createorderres = await createOrderdetails(pathName, wager, multiplebets, betamount, initialSelectedRpc, accountInfo, stopgain, stoploss, 0, 0, maxpayout, calculatewager, wagerclaimfee, '', '');
                                    let createTrans = await createTransactionHooks(reqData)
                                    let updatedata = { commissionfee: commissionfeetoFixed }
                                    let updateCommissionFee = await updateAdminCommissionDetails(updatedata)
                                    console.log("rssssssssssssss", createorderres, updateCommissionFee)
                                    if (createorderres?.status == 200 && createTrans?.status == 200) {
                                        if (createorderres?.data?.data) {
                                            setRpcStart(false)
                                        }
                                    }
                                } else {
                                    await ToastBet(Id, betdata);
                                    setBet(false)
                                    setDisabledRpcBtn(false)
                                }
                            } else if ((percentageamount < Number(min)) || (Number(max) < percentageamount)) {
                                if (percentageamount < Number(min)) {
                                    setWagErr(`Please choose atleast minimum ${min} HBLU`)
                                } else if (Number(max) < percentageamount) {
                                    // Value must be between 6 and 12
                                    setWagErr(`$Maximum allowed ${max} HBLU`)
                                }

                            } else {
                                setWagErr("")
                            }
                        } else {
                            setIsApproved(false);
                            await Toast("error", `You have approve only ${toFixedApproveAmount} HBLU`);
                        }

                        //even
                    } else {
                        if (wager == 0) {
                            await toast.error(`Please choose above 0.0001 wager`,
                                {
                                    duration: 4000,
                                    position: position.position,
                                    style: style,
                                    iconTheme: iconTheme
                                });
                        } else if (multiplebets == 0) {
                            await toast.error(`Please choose atleast 1 bet`,
                                {
                                    duration: 4000,
                                    position: position.position,
                                    style: style,
                                    iconTheme: iconTheme
                                });
                        }

                    }


                } else {
                    await Toast("error", "You don't have enough balance");
                    setIsApproved(false);
                }


            } else if (pathName == "Wheel") {
                if (depositTokenBalance >= wagerclaimfee) {
                    if (Number(toFixedApproveAmount) >= wagerclaimfee) {
                        if (initialSelectedWheel != 0) {
                            if (wagerclaimfee > 0 && wagerr == "") {
                                if (!(percentageamount < Number(min)) && !(Number(max) < percentageamount)) {
                                    let amount = Number(wagerclaimfee);
                                    const decimal = stakeTokens[0]?.decimal;
                                    const convertedAmount = getWholeNumber(amount, decimal);
                                    setBet(true)
                                    setDisabledWheelBtn(false);
                                    let address = await getAccount();
                                    console.log("address_address mmm", address);
                                    let Id = await ToastBet("", "loading")
                                    let toFixed = toFixedNumber(parseInt(convertedAmount));
                                    let commissionfeetoFixed = toFixedNumber(adminclaimfee);
                                    console.log('Wheel toFixed_amount', wagerclaimfee, convertedAmount, toFixed)
                                    let betdata = await UseUserBet(String(toFixed), stakeTokens[0]?.address, "2", "6", address);
                                    console.log("betdata_betdata", betdata, initialSelectedWheel)
                                    if (betdata != "rejected" && betdata != "something wrong !!!" && betdata != undefined) {
                                        await ToastBet(Id, betdata);
                                        let reqData = {
                                            walletAddress: accountInfo, userId: userdetails?._id, parentId: refferdetails?.parentId, gameName: pathName,
                                            amount: (calculatewager * multiplebets), commissionfee: commissionfeetoFixed, refferalfee: 0, totalamount: wagerclaimfee, status: 'Completed', type: 'Deposit',
                                            transtype: "Bet"
                                        }
                                        let createorderres = await createOrderdetails(pathName, wager, multiplebets, betamount, initialSelectedWheel, accountInfo, stopgain, stoploss, 0, 0, maxpayout, calculatewager, wagerclaimfee, '', '');
                                        let createTrans = await createTransactionHooks(reqData)
                                        let updatedata = { commissionfee: commissionfeetoFixed }
                                        let updateCommissionFee = await updateAdminCommissionDetails(updatedata)
                                        console.log("rssssssssssssss", createorderres, updateCommissionFee)
                                        if (createorderres?.status == 200 && createTrans?.status == 200) {
                                            if (createorderres?.data?.data) {
                                                setWheelStart(false)
                                            }
                                        }
                                    } else {
                                        await ToastBet(Id, betdata);
                                        setBet(false)
                                        // setDisabledWheelBtn(false);
                                    }
                                } else if ((percentageamount < Number(min)) || (Number(max) < percentageamount)) {
                                    if (percentageamount < Number(min)) {
                                        setWagErr(`Please choose atleast minimum ${min} HBLU`)
                                    } else if (Number(max) < percentageamount) {
                                        // Value must be between 6 and 12
                                        setWagErr(`$Maximum allowed ${max} HBLU`)
                                    }

                                } else {
                                    setWagErr("")
                                }

                            } else {
                                if (wager == 0) {
                                    await toast.error(`Please choose above 0.0001 wager`,
                                        {
                                            duration: 4000,
                                            position: position.position,
                                            style: style,
                                            iconTheme: iconTheme
                                        });
                                } else if (multiplebets == 0) {
                                    await toast.error(`Please choose atleast 1 bet`,
                                        {
                                            duration: 4000,
                                            position: position.position,
                                            style: style,
                                            iconTheme: iconTheme
                                        });
                                }

                            }
                        } else {
                            setBet(false);
                            await toast.error(`Please Choose Your Prediction Colour`,
                                {
                                    duration: 4000,
                                    position: position.position,
                                    style: style,
                                    iconTheme: iconTheme
                                });

                        }
                    } else {
                        await Toast("error", `You have approve only ${toFixedApproveAmount} HBLU`);
                        setIsApproved(false);
                    }
                } else {
                    await Toast("error", "You don't have enough balance")
                }
                console.log('selectedWheel_data', selectedWheel, initialSelectedWheel)



            } else if (pathName == "Limbo") {
                console.log("initialSelectedLimbo", initialSelectedLimbo)
                if (depositTokenBalance >= wagerclaimfee) {
                    if (Number(toFixedApproveAmount) >= wagerclaimfee) {
                        if (initialSelectedLimbo != 0) {
                            if (wagerclaimfee > 0 && wagerr == "") {

                                if (!(percentageamount < Number(min)) && !(Number(max) < percentageamount)) {
                                    let amount = Number(wagerclaimfee);
                                    const decimal = stakeTokens[0]?.decimal;
                                    const convertedAmount = getWholeNumber(amount, decimal);
                                    setBet(true);
                                    setDisabledLimboBtn(false);
                                    let address = await getAccount();
                                    console.log("address_address mmm", address);
                                    let Id = await ToastBet("", "loading");
                                    let toFixed = toFixedNumber(parseInt(convertedAmount))
                                    let commissionfeetoFixed = toFixedNumber(adminclaimfee);
                                    console.log('Limbo toFixed_amount', wagerclaimfee, convertedAmount, toFixed)
                                    let betdata = await UseUserBet(String(toFixed), stakeTokens[0]?.address, "2", "6", address);
                                    console.log("betdata_betdata", betdata, initialSelectedLimbo)
                                    if (betdata != "rejected" && betdata != "something wrong !!!" && betdata != undefined) {
                                        await ToastBet(Id, betdata);
                                        let reqData = {
                                            walletAddress: accountInfo, userId: userdetails?._id, parentId: refferdetails?.parentId, gameName: pathName,
                                            amount: (calculatewager * multiplebets), commissionfee: commissionfeetoFixed, refferalfee: 0, totalamount: wagerclaimfee, status: 'Completed', type: 'Deposit',
                                            transtype: "Bet"
                                        }
                                        console.log("reqdatasdfasdf", reqData)
                                        let createorderres = await createOrderdetails(pathName, wager, multiplebets, betamount, initialSelectedLimbo, accountInfo, stopgain, stoploss, 0, 0, maxpayout, calculatewager, wagerclaimfee, '', '');
                                        let createTrans = await createTransactionHooks(reqData)
                                        let updatedata = { commissionfee: commissionfeetoFixed }
                                        let updateCommissionFee = await updateAdminCommissionDetails(updatedata)
                                        console.log("rssssssssssssss", createorderres, updateCommissionFee)
                                        if (createorderres?.status == 200 && createTrans?.status == 200) {
                                            if (createorderres?.data?.data) {
                                                console.log("enter this response rssssssssssssss")
                                                setLimboStart(false)
                                            }
                                        }
                                    } else {
                                        await ToastBet(Id, betdata);
                                        setBet(false)
                                    }
                                } else if ((percentageamount < Number(min)) || (Number(max) < percentageamount)) {
                                    if (percentageamount < Number(min)) {
                                        setWagErr(`Please choose atleast minimum ${min} HBLU`)
                                    } else if (Number(max) < percentageamount) {
                                        // Value must be between 6 and 12
                                        setWagErr(`$Maximum allowed ${max} HBLU`)
                                    }
                                } else {
                                    setWagErr("")
                                }

                            } else {
                                if (wager == 0) {
                                    await toast.error(`Please choose above 0.0001 wager`,
                                        {
                                            duration: 4000,
                                            position: position.position,
                                            style: style,
                                            iconTheme: iconTheme
                                        });
                                } else if (multiplebets == 0) {
                                    await toast.error(`Please choose atleast 1 bet`,
                                        {
                                            duration: 4000,
                                            position: position.position,
                                            style: style,
                                            iconTheme: iconTheme
                                        });
                                }

                            }
                        } else {
                            setBet(false);
                            await toast.error(`Please Choose Your Limbo Range`,
                                {
                                    duration: 4000,
                                    position: position.position,
                                    style: style,
                                    iconTheme: iconTheme
                                });
                        }
                    } else {
                        await Toast("error", `You have approve only ${toFixedApproveAmount} HBLU`);
                        setIsApproved(false);
                    }
                } else {
                    await Toast("error", "You don't have enough balance")
                }


            } else if (pathName == "Moon") {
                if (depositTokenBalance >= wagerclaimfee) {
                    if (wagerclaimfee > 0 && wagerr == "") {
                        if (Number(toFixedApproveAmount) >= wagerclaimfee) {
                            if (!(percentageamount < Number(min)) && !(Number(max) < percentageamount)) {
                                console.log('111 amount-data', wagerclaimfee)
                                let amount = Number(wagerclaimfee);
                                console.log('222 amount-data', amount)
                                const decimal = stakeTokens[0]?.decimal;
                                const convertedAmount = getWholeNumber(amount, decimal);
                                setBet(true);
                                setDisabledMoonBtn(false)
                                let address = await getAccount();
                                console.log("address_address mmm", address);
                                let Id = await ToastBet("", "loading");
                                let toFixed = toFixedNumber(parseInt(convertedAmount))
                                let commissionfeetoFixed = toFixedNumber(adminclaimfee);
                                console.log('Moon toFixed_amount', wagerclaimfee, convertedAmount, toFixed)
                                let betdata = await UseUserBet(String(toFixed), stakeTokens[0]?.address, "2", "6", address);
                                if (betdata != "rejected" && betdata != "something wrong !!!" && betdata != undefined) {
                                    await ToastBet(Id, betdata);
                                    let reqData = {
                                        walletAddress: accountInfo, userId: userdetails?._id, parentId: refferdetails?.parentId, gameName: pathName,
                                        amount: (calculatewager * multiplebets), commissionfee: commissionfeetoFixed, refferalfee: 0, totalamount: wagerclaimfee, status: 'Completed', type: 'Deposit',
                                        transtype: "Bet"
                                    }
                                    console.log('reqdatamoon', reqData)
                                    console.log('moongame ordercreate', pathName, wager, multiplebets, betamount, (wager * multiplebets), accountInfo, stopgain, stoploss, 0, 0, maxpayout, calculatewager, wagerclaimfee)
                                    let createorderres = await createOrderdetails(pathName, wager, multiplebets, betamount, (wager * multiplebets), accountInfo, stopgain, stoploss, 0, 0, maxpayout, calculatewager, wagerclaimfee, '', '');
                                    let createTrans = await createTransactionHooks(reqData)
                                    let updatedata = { commissionfee: commissionfeetoFixed }
                                    let updateCommissionFee = await updateAdminCommissionDetails(updatedata)
                                    console.log("rssssssssssssss", createorderres, updateCommissionFee)
                                    if (createorderres?.status == 200 && createTrans?.status == 200) {
                                        if (createorderres?.data?.data) {
                                            console.log("enter this moon response rssssssssssssss")
                                            setMoonStart(false)
                                        }
                                    }
                                } else {
                                    await ToastBet(Id, betdata);
                                    setBet(false)
                                }
                            } else if ((percentageamount < Number(min)) || (Number(max) < percentageamount)) {
                                if (percentageamount < Number(min)) {
                                    setWagErr(`Please choose atleast minimum ${min} HBLU`)
                                } else if (Number(max) < percentageamount) {
                                    // Value must be between 6 and 12
                                    setWagErr(`$Maximum allowed ${max} HBLU`)
                                }

                            } else {
                                setWagErr("")
                            }
                        } else {
                            await Toast("error", `You have approve only ${toFixedApproveAmount} HBLU`);
                            setIsApproved(false);
                        }

                    } else {
                        if (wager == 0) {
                            await toast.error(`Please choose above 1 wager`,
                                {
                                    duration: 4000,
                                    position: position.position,
                                    style: style,
                                    iconTheme: iconTheme
                                });
                        } else if (multiplebets == 0) {
                            await toast.error(`Please choose atleast 1 bet`,
                                {
                                    duration: 4000,
                                    position: position.position,
                                    style: style,
                                    iconTheme: iconTheme
                                });
                        }

                    }

                } else {
                    await Toast("error", "You don't have enough balance")
                }

            } else if (pathName == "Range") {
                console.log('depositTokenBalance', depositTokenBalance, wagerclaimfee)
                if (depositTokenBalance >= wagerclaimfee) {
                    if (wagerclaimfee > 0 && wagerr == "") {
                        if (Number(toFixedApproveAmount) >= wagerclaimfee) {
                            console.log('initialselectedrange', initialselectedrange)
                            if (!(percentageamount < Number(min)) && !(Number(max) < percentageamount)) {
                                if (initialselectedrange >= 5 && 95 >= initialselectedrange) {
                                    let amount = Number(wagerclaimfee);
                                    console.log('amountclaimdata', amount)
                                    const decimal = stakeTokens[0]?.decimal;
                                    const convertedAmount = getWholeNumber(amount, decimal);
                                    setBet(true)
                                    setDisabledRangeBtn(false)
                                    let address = await getAccount();
                                    console.log("address_address mmm", address);
                                    let Id = await ToastBet("", "loading")
                                    console.log("iasdfasdfasd", Id)
                                    let toFixed = toFixedNumber(parseInt(convertedAmount));
                                    let commissionfeetoFixed = toFixedNumber(adminclaimfee);
                                    console.log('Range toFixed_amount', wagerclaimfee, convertedAmount, toFixed)
                                    console.log("asdfasdfsasdf", adminclaimfee, parseInt(adminclaimfee), commissionfeetoFixed)
                                    console.log('amount iasdfasdfasd', multiplebets, wager, parseInt(convertedAmount), toFixed)
                                    let betdata = await UseUserBet(String(toFixed), stakeTokens[0]?.address, "2", "6", address);
                                    console.log("betdata_betdata", betdata)

                                    if (betdata != "rejected" && betdata != "something wrong !!!") {
                                        if (betdata != undefined) {
                                            await ToastBet(Id, betdata);
                                            let reqData = {
                                                walletAddress: accountInfo, userId: userdetails?._id, parentId: refferdetails?.parentId, gameName: pathName,
                                                amount: (calculatewager * multiplebets), commissionfee: commissionfeetoFixed, refferalfee: 0, totalamount: wagerclaimfee, status: 'Completed', type: 'Deposit',
                                                transtype: "Bet"
                                            }
                                            console.log("reqdatasdfasdf", reqData);
                                            let createorderres = await createOrderdetails(pathName, wager, multiplebets, betamount, initialselectedrange, accountInfo, stopgain, stoploss, 0, 0, maxpayout, calculatewager, wagerclaimfee, [], rangemultiplier);
                                            let createTrans = await createTransactionHooks(reqData)
                                            let updatedata = { commissionfee: commissionfeetoFixed };
                                            let updateCommissionFee = await updateAdminCommissionDetails(updatedata);
                                            console.log("createorderres", createorderres)
                                            console.log("rssssssssssssss", createorderres, createTrans, updateCommissionFee);
                                            if (createorderres?.status == 200 && createTrans?.status == 200) {
                                                if (createorderres?.data?.data) {
                                                    setRangeStart(false)
                                                }
                                            }
                                        } else {
                                            await ToastBet(Id, betdata);
                                            setBet(false)
                                            setDisabledRangeBtn(false)
                                        }
                                    }
                                } else {
                                    setBet(false);
                                    // setDisabledtossBtn(false);
                                    await toast.error(`Please Choose Your Range 5 to 95 `,
                                        // {
                                        //     duration: 4000, style: { minWidth: '250px', minHeight: '55px' },
                                        // }
                                        {
                                            duration: 4000,
                                            position: position.position,
                                            style: style,
                                            iconTheme: iconTheme
                                        }
                                    );

                                }
                            } else if ((percentageamount < Number(min)) || (Number(max) < percentageamount)) {
                                if (percentageamount < Number(min)) {
                                    setWagErr(`Please choose atleast minimum ${min} HBLU`)
                                } else if (Number(max) < percentageamount) {
                                    // Value must be between 6 and 12
                                    setWagErr(`$Maximum allowed ${max} HBLU`)
                                }
                            } else {
                                setWagErr("")
                            }

                        } else {
                            await Toast("error", `You have approve only ${toFixedApproveAmount} HBLU`);
                            setIsApproved(false);
                        }
                    } else {
                        if (wager == 0) {
                            await toast.error(`Please choose above 0.0001 wager`,
                                {
                                    duration: 4000,
                                    position: position.position,
                                    style: style,
                                    iconTheme: iconTheme
                                });
                        } else if (multiplebets == 0) {
                            await toast.error(`Please choose atleast 1 bet`,
                                {
                                    duration: 4000,
                                    position: position.position,
                                    style: style,
                                    iconTheme: iconTheme
                                });
                        }

                    }
                } else {
                    await Toast("error", "You don't have enough balance")
                }
            } else if (pathName == "Plinko") {
                if (depositTokenBalance >= wagerclaimfee) {
                    if (wagerclaimfee > 0 && wagerr == "") {
                        if (Number(toFixedApproveAmount) >= wagerclaimfee) {
                            if (!(percentageamount < Number(min)) && !(Number(max) < percentageamount)) {
                                console.log('intially amountplinkogame', wagerclaimfee);
                                let amount = Number(wagerclaimfee);
                                console.log("amount_plinko", multiplebets, wager, amount);
                                const decimal = stakeTokens[0]?.decimal;
                                let tofixed = Number(amount).toFixed(4);
                                console.log("tofxiex", amount, tofixed);
                                const convertedAmount = getWholeNumber(amount, decimal);
                                setBet(true);
                                setDisabledPlinkoBtn(false);
                                let address = await getAccount();
                                console.log("address_address mmm", address);
                                let Id = await ToastBet("", "loading")
                                let toFixed = toFixedNumber(parseInt(convertedAmount));
                                console.log('Plinko toFixed_amount', wagerclaimfee, convertedAmount, toFixed)
                                let commissionfeetoFixed = toFixedNumber(adminclaimfee);
                                let betdata = await UseUserBet(String(toFixed), stakeTokens[0]?.address, "2", "6", address);
                                console.log("betdata_betdata", betdata);
                                if (betdata != "rejected" && betdata != "something wrong !!!" && betdata != undefined) {
                                    await ToastBet(Id, betdata);
                                    let reqData = {
                                        walletAddress: accountInfo, userId: userdetails?._id, parentId: refferdetails?.parentId, gameName: pathName,
                                        amount: (calculatewager * multiplebets), commissionfee: commissionfeetoFixed, refferalfee: 0, totalamount: wagerclaimfee, status: 'Completed', type: 'Deposit',
                                        transtype: "Bet"
                                    };
                                    console.log('reqDatasgal', reqData, calculatewager);
                                    let createorderres = await createOrderdetails(pathName, wager, multiplebets, betamount, plinkobets, accountInfo, stopgain, stoploss, 0, 0, maxpayout, calculatewager, wagerclaimfee, '', '');
                                    let createTrans = await createTransactionHooks(reqData)
                                    let updatedata = { commissionfee: commissionfeetoFixed };
                                    let updateCommissionFee = await updateAdminCommissionDetails(updatedata)
                                    console.log("rssssssssssssss", createorderres, updateCommissionFee);
                                    if (createorderres?.status == 200 && createTrans?.status == 200) {
                                        if (createorderres?.data?.data) {
                                            setPlinkoStart(false)
                                        }
                                    }
                                } else {
                                    await ToastBet(Id, betdata);
                                    setBet(false)
                                    setDisabledPlinkoBtn(false)
                                }
                            } else if ((percentageamount < Number(min)) || (Number(max) < percentageamount)) {
                                if (percentageamount < Number(min)) {
                                    setWagErr(`Please choose atleast minimum ${min} HBLU`)
                                } else if (Number(max) < percentageamount) {
                                    // Value must be between 6 and 12
                                    setWagErr(`$Maximum allowed ${max} HBLU`)
                                }

                            } else {
                                setWagErr("")
                            }
                        } else {
                            await Toast("error", `You have approve only ${toFixedApproveAmount} HBLU`);
                            setIsApproved(false);
                        }
                    } else {
                        if (wager == 0) {
                            await toast.error(`Please choose above 0.0001 wager`,
                                {
                                    duration: 4000,
                                    position: position.position,
                                    style: style,
                                    iconTheme: iconTheme
                                });
                        } else if (multiplebets == 0) {
                            await toast.error(`Please choose atleast 1 bet`,
                                {
                                    duration: 4000,
                                    position: position.position,
                                    style: style,
                                    iconTheme: iconTheme
                                });
                        }
                    }
                } else {
                    await Toast("error", "You don't have enough balance")
                }
            }

        } catch (e) {
            console.log("Bet_err", e)
        }
    }


    const handleOnChange = (value) => {
        if (!bet) {
            let min = 0;
            let max = 0;
            let percentageamount = ((depositTokenBalance * wager) / 100) * Number(value);
            console.log('multiplebtes percet', percentageamount);
            gameCard && gameCard?.length > 0 && gameCard?.map((val, i) => {
                console.log("val_datassss", val?.GameName, val?.GameName == pathName, val?.BetPercentage[0]);
                if (val?.GameName == pathName) {
                    min = val?.BetPercentage[0]['Min'];
                    max = val?.BetPercentage[1]['Max'];
                }
            });
            console.log("min, max values", min, max);
            if ((percentageamount < Number(min)) || (Number(max) < percentageamount)) {
                if (percentageamount < Number(min)) {
                    setWagErr(`Please choose atleast minimum ${min} HBLU`)
                } else if (Number(max) < percentageamount) {
                    // Value must be between 6 and 12
                    setWagErr(`$Maximum allowed ${max} HBLU`)
                }
            } else {
                setWagErr("")
            }
            console.log("multiple_handleOnChange", value)
            // setvolumes(value)
            setMultipleBets(value)
        }
    }

    const handleOnChange3 = (value) => {
        if (!bet) {
            if (6 <= Number(value) && 12 >= Number(value)) {
                setPlinkoBets(value)
                setError1('')
            }

        }
    }


    const HandleOnChange3 = (e) => {
        if (!bet) {
            console.log('plllllllllllllllllll', e?.target?.value, 6 <= Number(e?.target?.value) && 12 >= Number(e?.target?.value))
            if ((e.target.value == '') || regex.test(e.target.value) && 6 <= Number(e?.target?.value) && 12 >= Number(e?.target?.value)) {
                setPlinkoBets(e?.target?.value)
                setError1('')
            }
            else if (!(6 <= Number(e?.target?.value) && 12 >= Number(e?.target?.value))) {
                setError1('Value must be between 6 and 12')
            }
        }

    }


    const HandleOnChange = (e) => {
        if (!bet) {
            if ((e.target.value == '') || regex.test(e.target.value) && Number(e?.target?.value) <= 100) {
                let min = 0;
                let max = 0;
                let percentageamount = ((depositTokenBalance * wager) / 100) * Number(e?.target?.value);
                console.log('multiplebtes percet', percentageamount)
                gameCard && gameCard?.length > 0 && gameCard?.map((val, i) => {
                    console.log("val_datassss", val?.GameName, val?.GameName == pathName, val?.BetPercentage[0]);
                    if (val?.GameName == pathName) {
                        min = val?.BetPercentage[0]['Min'];
                        max = val?.BetPercentage[1]['Max'];
                    }
                });
                console.log("min, max values", min, max);
                if ((percentageamount < Number(min)) || (Number(max) < percentageamount)) {
                    if (percentageamount < Number(min)) {
                        setWagErr(`Please choose atleast minimum ${min} HBLU`)
                    } else if (Number(max) < percentageamount) {
                        // Value must be between 6 and 12
                        setWagErr(`$Maximum allowed ${max} HBLU`)
                    }

                } else {
                    setWagErr("")
                }
                setMultipleBets(e?.target?.value)
                setError('')
            }
            else if (Number(e?.target?.value) >= 100) {
                setError('Value must be between 1 and 100')
            }
        }

    }

    const handleOnChange1 = (value) => {
        if (!bet) {
            console.log("wager_handleOnChange", depositTokenBalance, value, (depositTokenBalance * value) / 100);
            console.log("wager_handleOnChange", pathName);
            let min = 0;
            let max = 0;
            gameCard && gameCard?.length > 0 && gameCard?.map((val, i) => {
                console.log("val_datassss", val?.GameName, val?.GameName == pathName, val?.BetPercentage[0]);
                if (val?.GameName == pathName) {
                    min = val?.BetPercentage[0]['Min'];
                    max = val?.BetPercentage[1]['Max'];
                }
            });
            console.log("min, max values", min, max);
            let percentageamountsdata = ((depositTokenBalance * value) / 100);
            let percentageamount = ((depositTokenBalance * value) / 100) * multiplebets;
            console.log("min err", min, max, percentageamount, percentageamount < Number(min), "max err", Number(max) < percentageamount);
            if ((percentageamount < Number(min)) || (Number(max) < percentageamount)) {
                if (percentageamount < Number(min)) {
                    setWagErr(`Please choose atleast minimum ${min} HBLU`)
                } else if (Number(max) < percentageamount) {
                    // Value must be between 6 and 12
                    setWagErr(`$Maximum allowed ${max} HBLU`)
                }
            } else {
                setWagErr("")
            }
            console.log("percentageamount_wager", percentageamount, percentageamountsdata)
            setBetAmount(percentageamountsdata);
            setCalculateWager(percentageamountsdata)
            // setvolumess(value)
            setWager(value)
        }

    }

    const HandleOnChange1 = (e) => {
        if (!bet) {
            console.log('Number(e?.target?.value)', pathName, e.target.value, (e.target.value == '') || regex.test(e.target.value))
            if (pathName == "Moon") {
                if ((e.target.value == '') || decregex.test(e.target.value) && (1 <= Number(e?.target?.value) && Number(e?.target?.value) <= 100)) {
                    setWager(e?.target?.value)
                    let percentageamount = (Number(depositTokenBalance) * Number(e?.target?.value)) / 100;
                    console.log("percentageamount_wager", percentageamount)
                    setCalculateWager(percentageamount)
                }
            } else {
                console.log('Number(e?.target?.value)', Number(e?.target?.value), Number(e?.target?.value) <= 0.0001)
                if ((e.target.value == '') || decregex.test(e.target.value) && Number(e?.target?.value) <= 100) {
                    setWager(e?.target?.value)
                    let percentageamount = (Number(depositTokenBalance) * Number(e?.target?.value)) / 100;
                    console.log("percentageamount_wager", percentageamount)
                    setCalculateWager(percentageamount)
                }

                if (Number(e?.target?.value) < 0.0001) {
                    setWagErr('choose value between 0.0001 & 100')
                } else {
                    setWagErr('')
                }
            }

        }
    }


    const handleOnChange2 = (value) => {
        setvolumeup(value)
    }

    const handleChange3 = (e) => {
        console.log("handleChange3", e.target.value)
        if (e.target.name == "stoploss") {
            if (Number(e?.target?.value) <= 100) {
                setStopLoss(e.target.value);
            }
        } else {
            if (Number(e?.target?.value) <= 100) {
                setStopGain(e.target.value)
            }
        }
    }

    const wagerRange = async (value) => {
        try {
            if (!bet) {
                if (value == "1/3") {
                    console.log("value_data", value, value == "1/3")
                    let percentagecalculate = parseInt(depositTokenBalance * 33.33) / 100;
                    setBetAmount(percentagecalculate);
                    // setvolumess(33.33);
                    setWager(33.33)
                    setCalculateWager(percentagecalculate)
                } else if (value == "2x") {
                    let percentagecalculate = parseInt(depositTokenBalance * 50) / 100;
                    setBetAmount(percentagecalculate);
                    // setvolumess(50);
                    setWager(50);
                    setCalculateWager(percentagecalculate)
                } else {
                    let percentagecalculate = parseInt(depositTokenBalance * 100) / 100;
                    console.log("percentagecalculate getfeedata", depositTokenBalance, percentagecalculate)
                    setBetAmount(percentagecalculate);
                    // setvolumess(100);
                    setWager(100);
                    setCalculateWager(percentagecalculate)
                }

            }

        } catch (err) {
            console.log("wagerRange_err", err)
        }
    }

    const Start = async () => {
        try {
            setPlay(true);
            let sum = 0;
            for (let i = 0; i < selected.length; i++) {
                sum = Number(sum) + Number(selected[i]);
            }
            setPrediction(sum);
        } catch (err) {
            console.log("Start_err", err)
        }
    }



    return (
        <>

            {dicemodal && <Dicemodal onDismiss={() => setdicemodal(false)} gamedetails={gameInfo?.GamePlay} title={gameInfo?.Title} content={gameInfo?.Content} />}
            {walletmodal && <Wallets onDismiss={() => setWalletmodal(false)} />}
            <div className="sidebarlist">
                <Gameheader />
                {/* <Sidebar /> */}


                <div className="content pt-3">
                    <div className="container container_max">

                        {/* <Sidebarheader /> */}
                        <div className="d-none d-xl-block">
                            <Sidebar /></div>
                        <div className="content_right innerright pt-5">

                            {/* problem */}

                            <div className="row mt-5">
                                <h4 className="ftext mb-3">HONEY <span className="sectext">BLUE</span></h4>
                                <div class="col-12 col-md-12 col-lg-8 px-0 px-sm-2 mt-4 mt-lg-0">

                                    {/* <div className="gameborder pt-5"> */}
                                    <div className=
                                        {pathName == "Dice" ? "gameborder dicebackimg pos pt-5 pb-4" :
                                            pathName == "Range" ? "gameborder rangebackimg  pt-5" :
                                                pathName == "CoinFlip" ? "gameborder coinflipbackimg pt-5" :
                                                    pathName == "Wheel" ? "gameborder wheelbackimg pt-5" :
                                                        pathName == "Plinko" ? "gameborder plinkobackimg pt-4" :
                                                            pathName == "Moon" ? "gameborder moonbackimg pt-5 pb-4" :
                                                                pathName == "Limbo" ? "gameborder limbobackimg pt-5" :
                                                                    "gameborder rpsbackimg pt-5 pb-4"}>
                                        {pathName == "Dice" &&
                                            <>
                                                <img src={flip1} className='img-fluid diceall diceimg1' />
                                                <img src={flip1} className='img-fluid diceall diceimg3' />
                                                <img src={flip2} className='img-fluid diceall diceimg2' />
                                                <img src={flip3} className='img-fluid diceall diceimg4' />
                                                <span className="squaress square1"></span>
                                                <span className="squaress square2"></span>
                                                <span className="squaress square3"></span>
                                                <span className="squaress square4"></span>
                                                <span className="squaress square5"></span>
                                                <span className="squaress square6"></span>
                                                <span className="squaress square7"></span>
                                            </>
                                        }

                                        {console.log("accountInfo_accountInfo", accountInfo, accountInfo != null && accountInfo != '', accountInfo != null, accountInfo != "")}
                                        {((accountInfo != "" || accountInfo != null) && !play && (pathName == "Dice")) ?
                                            <div>

                                                <h2 className="whtclr poppins text-center mb-4">Dice</h2>
                                                {<div className='styles_dice'>

                                                    {/* dice 1  */}
                                                    {console.log(selected, "selected")}
                                                    {<div className='dice_box' data-value="1"
                                                        data-state={selected.indexOf(1) > -1 == 1 ? "selected" : ""}
                                                        onClick={() => { setSelected(selected.some(val => val == 1) ? selected.filter(val => val != 1) : (selected.length >= 2) ? [...selected] : [...selected, 1]) }}>
                                                        <div className='dice_dot dice_dot_one_1'></div>
                                                    </div>}
                                                    {/* end dice 1  */}
                                                    {/* dice 2 */}
                                                    <div className='dice_box' data-value="2"
                                                        data-state={selected.indexOf(2) > -1 == 1 ? "selected" : ""}
                                                        onClick={() => { setSelected(selected.some(val => val == 2) ? selected.filter(val => val != 2) : (selected.length >= 2) ? [...selected] : [...selected, 2]) }}>
                                                        <div className='dice_dot dice_dot_two_1'></div>
                                                        <div className='dice_dot dice_dot_two_2'></div>
                                                    </div>
                                                    {/* end dice 2 */}

                                                    {/* dice 3 */}
                                                    <div className='dice_box' data-value="3"
                                                        data-state={selected.indexOf(3) > -1 == 1 ? "selected" : ""}
                                                        onClick={() => { setSelected(selected.some(val => val == 3) ? selected.filter(val => val != 3) : (selected.length >= 2) ? [...selected] : [...selected, 3]) }}>
                                                        <div className='dice_dot dice_dot_three_1'></div>
                                                        <div className='dice_dot dice_dot_three_2'></div>
                                                        <div className='dice_dot dice_dot_three_3'></div>
                                                    </div>
                                                    {/* end dice 3 */}

                                                    {/* dice 4 */}
                                                    <div className='dice_box' data-value="4"
                                                        data-state={selected.indexOf(4) > -1 == 1 ? "selected" : ""}
                                                        onClick={() => { setSelected(selected.some(val => val == 4) ? selected.filter(val => val != 4) : (selected.length >= 2) ? [...selected] : [...selected, 4]) }}>
                                                        <div className='dice_dot dice_dot_four_1'></div>
                                                        <div className='dice_dot dice_dot_four_2'></div>
                                                        <div className='dice_dot dice_dot_four_3'></div>
                                                        <div className='dice_dot dice_dot_four_4'></div>
                                                    </div>
                                                    {/* end dice 4 */}

                                                    {/* dice 5 */}
                                                    <div className='dice_box' data-value="5"
                                                        data-state={selected.indexOf(5) > -1 == 1 ? "selected" : ""}
                                                        onClick={() => { setSelected(selected.some(val => val == 5) ? selected.filter(val => val != 5) : (selected.length >= 2) ? [...selected] : [...selected, 5]) }}>
                                                        <div className='dice_dot dice_dot_five_1'></div>
                                                        <div className='dice_dot dice_dot_five_2'></div>
                                                        <div className='dice_dot dice_dot_five_3'></div>
                                                        <div className='dice_dot dice_dot_five_4'></div>
                                                        <div className='dice_dot dice_dot_five_5'></div>
                                                    </div>
                                                    {/* end dice 5 */}

                                                    {/* dice 6 */}
                                                    <div className='dice_box' data-value="6"
                                                        data-state={selected.indexOf(6) > -1 == 1 ? "selected" : ""}
                                                        onClick={() => { setSelected(selected.some(val => val == 6) ? selected.filter(val => val != 6) : (selected.length >= 2) ? [...selected] : [...selected, 6]) }}>
                                                        <div className='dice_dot dice_dot_six_1'></div>
                                                        <div className='dice_dot dice_dot_six_2'></div>
                                                        <div className='dice_dot dice_dot_six_3'></div>
                                                        <div className='dice_dot dice_dot_six_4'></div>
                                                        <div className='dice_dot dice_dot_six_5'></div>
                                                        <div className='dice_dot dice_dot_six_6'></div>

                                                    </div>
                                                    {/* end dice 6 */}



                                                </div>}
                                                {console.log('playnormalgame', play)}
                                                {/* no problem */}
                                                <div className="row">
                                                    <div className="col-sm-8 col-lg-6 col-md-8 mx-auto">
                                                        <div className="row">
                                                            {/* <div className="col-md-6 col-6 col-sm-6"><label className="form-label Multi f-15 fw-700">Multiplayer</label> <div className="borders d-flex justify-content-between">
                        <p className="mb-0 f-15">0X</p>
                    </div> </div> */}
                                                            <div className="col-md-6 col-6 col-sm-6"><label className="form-label Multi f-15 fw-700 whtclr mt-4">Win Chance</label> <div className="borders d-flex justify-content-between">
                                                                <p className="mb-0 f-15">{winchance}%</p>
                                                            </div> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='container game_container dice_btn rolldice'>
                                                    {console.log("bet, start", bet, start)}
                                                    {console.log("selected.length <= 1", (selected.length <= 1 || (accountInfo == null || accountInfo == '')), selected, selected.length <= 1, start)}
                                                    <button className="btn btn-warning wallet play mt-4" disabled={(selected.length <= 1 || (accountInfo == null || accountInfo == '')) || start} onClick={() => { Start() }}> Start
                                                    </button>
                                                </div>


                                            </div>


                                            // no problem


                                            :
                                            // {remembers = depositAmount}

                                            <>{(pathName == "Dice") && (accountInfo != null || accountInfo != "" && play) &&
                                                <div className="">

                                                    <Dice selected={selected} wager={wager} multiplebets={multiplebets} betamount={betamount} maxpayout={maxpayout} totalwager={wagerclaimfee} oneweekwondata={oneweekwonInfo} onemonthwondata={onemonthwonInfo} onCheck={() => { setCheck(false) }} gameInfo={gameInfo} usergamedetails={usergamedetails} pathName={pathName} stoploss={stoploss} stopgain={stopgain} checkOrder={() => { getOrderData() }} remainingbets={remainingbets} gamepercentage={gamepercentage} calculatewager={calculatewager} setFeeData={() => { setFeeData(true) }} /></div>
                                            }</>
                                        }
                                        {console.log('accountInfo_accountInfo who come first', bet, disabledtossbtn)}
                                        {(pathName == "CoinFlip") && (accountInfo != null || accountInfo != "") ? <Coinflippage wager={wager} totalwager={wagerclaimfee} multiplebets={multiplebets} maxpayout={maxpayout} oneweekwondata={oneweekwonInfo} onemonthwondata={onemonthwonInfo} onCheck={() => { setCheck(false) }} usergamedetails={usergamedetails} pathName={pathName} stoploss={stoploss} stopgain={stopgain} remainingbets={remainingbets} checkOrder={() => { getOrderData() }} InitialselectedCoin={(data) => { setInitalSelectedCoin(data) }} selectedCoin={selectedcoin} bet={bet} gameInfo={gameInfo} disabledtossbtn={disabledtossbtn} setTossStart={(data) => setTossStart(data)} gamepercentage={gamepercentage} calculatewager={calculatewager} setFeeData={() => { setFeeData(true) }} /> : <></>}

                                        {(pathName == "RPC") && (accountInfo != null || accountInfo != "") ? <Rpspage wager={wager} totalwager={wagerclaimfee} multiplebets={multiplebets} maxpayout={maxpayout} oneweekwondata={oneweekwonInfo} onemonthwondata={onemonthwonInfo} onCheck={() => { setCheck(false) }} usergamedetails={usergamedetails} pathName={pathName} stoploss={stoploss} stopgain={stopgain} remainingbets={remainingbets} checkOrder={() => { getOrderData() }} bet={bet} gameInfo={gameInfo} disabledtossbtn={disabledtossbtn} selectedRpc={selectedRpc} setRpcStart={(data) => setRpcStart(data)} InitialSelectedRpc={(data) => { setInitialSelectedRpc(data) }} disabledRpcbtn={disabledRpcbtn} gamepercentage={gamepercentage} calculatewager={calculatewager} setFeeData={() => { setFeeData(true) }} /> : <></>}



                                        {(pathName == "Wheel") && (accountInfo != null || accountInfo != "") ? <Wheelpage wager={wager} totalwager={wagerclaimfee} multiplebets={multiplebets} maxpayout={maxpayout} oneweekwondata={oneweekwonInfo} onemonthwondata={onemonthwonInfo} onCheck={() => { setCheck(false) }} usergamedetails={usergamedetails} pathName={pathName} stoploss={stoploss} stopgain={stopgain} remainingbets={remainingbets} checkOrder={() => { getOrderData() }} bet={bet} gameInfo={gameInfo} selectedWheel={selectedWheel} disabledWheelbtn={disabledWheelbtn} setInitialSelectedWheel={(data) => { setInitialSelectedWheel(data) }} gamepercentage={gamepercentage} calculatewager={calculatewager} setFeeData={() => { setFeeData(true) }} /> : <></>}

                                        {(pathName == "Limbo") && (accountInfo != null || accountInfo != "") ? <Limbogame wager={wager} totalwager={wagerclaimfee} setInitialSelectedLimbo={(data) => { setInitialSelectedLimbo(data) }} disabledLimbobtn={disabledlimbobtn} onCheck={() => { setCheck(false) }} selectedLimbo={selectedLimbo} usergamedetails={usergamedetails} pathName={pathName} stoploss={stoploss} stopgain={stopgain} remainingbets={remainingbets} checkOrder={() => { getOrderData() }} bet={bet} gameInfo={gameInfo} multiplebets={multiplebets} maxpayout={maxpayout} setDragval={(value) => setLimboDragval(value)} gamepercentage={gamepercentage} calculatewager={calculatewager} setFeeData={() => { setFeeData(true) }} /> : <></>
                                        }
                                        {console.log("remainingbets_remainingbets", remainingbets)}
                                        {(pathName == "Plinko") && (accountInfo != null || accountInfo != "") ? <Plink wager={wager} setPlinkoStart={(data) => setPlinkoStart(data)} totalwager={wagerclaimfee} onCheck={() => { setCheck(false) }} usergamedetails={usergamedetails} pathName={pathName} stoploss={stoploss} stopgain={stopgain} remainingbets={remainingbets} checkOrder={() => { getOrderData() }} bet={bet} gameInfo={gameInfo} multiplebets={multiplebets} maxpayout={maxpayout} gamepercentage={gamepercentage} calculatewager={calculatewager} setFeeData={() => { setFeeData(true) }} disabledPlinkobtn={disabledPlinkobtn} plinkobets={plinkobets} /> : <></>
                                        }

                                        {(pathName == "Moon") && (accountInfo != null || accountInfo != "") ?
                                            <Moongametwo disabledmoonbtn={disabledmoonbtn} bet={bet} wager={wager} totalwager={wagerclaimfee} onCheck={() => { setCheck(false) }} usergamedetails={usergamedetails} pathName={pathName} stoploss={stoploss} stopgain={stopgain} remainingbets={remainingbets} checkOrder={() => { getOrderData() }} gameInfo={gameInfo} multiplebets={multiplebets} maxpayout={maxpayout}
                                                gamepercentage={gamepercentage} calculatewager={calculatewager} setFeeData={() => { setFeeData(true) }} /> : <></>
                                        }

                                        {(pathName == "Range") && (accountInfo != null || accountInfo != "") ? <Rangegame disabledrangebtn={disabledrangebtn} bet={bet} wager={wager} totalwager={wagerclaimfee} onCheck={() => { setCheck(false) }} checkOrder={() => { getOrderData() }} usergamedetails={usergamedetails} pathName={pathName} stoploss={stoploss} stopgain={stopgain} remainingbets={remainingbets} gameInfo={gameInfo} multiplebets={multiplebets} maxpayout={maxpayout} setInitialRange={(data) => setInitialSelectedRange(data)} setMaxpayout={(data) => setRangeMaxPayout(data)} selectedrange={selectedrange} gamepercentage={gamepercentage} calculatewager={calculatewager} rangearray={rangearray}
                                            setFeeData={() => { setFeeData(true) }} setRangeVal={(data) => { setRangeVal(data) }} rangemultiplier={rangemultiplier} /> : <></>
                                        }




                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-4 mt-4 mt-lg-0">
                                    <div className="number_border px-3 py-4">
                                        <div className="d-flex justify-content-between">
                                            <p className="fw-600">Percentage</p> <p className="fw-600">{console.log("depositTokenBalance", depositTokenBalance)}{depositTokenBalance} {stakeTokens[0].token}</p>
                                        </div>
                                        <div className="position-relative range1">
                                            {/* <p className="mb-0 nochange1">{wager}</p> */}
                                            <Slider
                                                min={0}
                                                max={100}
                                                value={wager}
                                                onChange={handleOnChange1}
                                                tooltip={false}
                                            />
                                            <div class="input-group mb-3">
                                                <input type="text" class="form-control" id="example" aria-describedby="number" value={wager} onChange={(e) => { HandleOnChange1(e) }} />
                                                {/* <p className="mb-0 nochange">$ {counter}</p>
                                            <span class="input-group-text" id="basic-addon1"><BsArrowUpShort onClick={incrementCounter} className="uparrow" /><BsArrowDownShort onClick={decrementCounter} className="downarrow" /><img className="mr-3" src={tablecoin} alt="images" /></span> */}

                                            </div>

                                        </div>


                                        <div className="mb-3">
                                            <div className="d-flex mb-1">
                                                <button type="button" class="btn btn-dark fw-700" onClick={() => { wagerRange("1/3") }}>1/3</button>
                                                <button type="button" class="btn btn-dark mx-2 fw-700" onClick={() => { wagerRange("2x") }}>2x</button>
                                                <button type="button" class="btn btn-dark fw-700" onClick={() => { wagerRange("Max") }}>Max</button>

                                            </div>
                                            <span className="text-danger backred">{wagerr}</span>

                                        </div>

                                        {gameInfo && gameInfo?.AdditionalRequirements?.length > 0 && gameInfo?.AdditionalRequirements[0].Type && <div className="d-flex justify-content-between">
                                            <p className="fw-600">{gameInfo?.AdditionalRequirements[0].Title}</p>
                                        </div>}

                                        {gameInfo && gameInfo?.AdditionalRequirements?.length > 0 && gameInfo?.AdditionalRequirements[0].Type && <div class="input-group mb-3">
                                            {console.log("prediction_data", prediction, gameInfo?.AdditionalRequirements)}
                                            {/* onChange={(e) => { if (e.target.value == '' || regex.test(e.target.value)) { if (e.target.value <= 12) { setPrediction(e.target.value) } } }} */}
                                            {gameInfo && gameInfo?.AdditionalRequirements?.length > 0 && gameInfo?.AdditionalRequirements[0].Type && <input type={gameInfo && gameInfo?.AdditionalRequirements?.length > 0 && gameInfo?.AdditionalRequirements[0].Type ? gameInfo?.AdditionalRequirements[0].Type : ""} class="form-control" id="example" value={prediction} aria-describedby="number" />}

                                        </div>}
                                        <label className="mb-2 fw-600 whtclr">Multiple Bets
                                            <OverlayTrigger
                                                className="toltip_style"
                                                key="top"
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-top`}>
                                                        Set the number of bets you want to wager in a single transaction
                                                    </Tooltip>
                                                }
                                            >
                                                <Button className="bet_overlay_icon"><BsQuestionCircle className="que" /></Button>
                                            </OverlayTrigger>
                                            {/* <BsQuestionCircle className="que" /> */}
                                        </label>

                                        <div class="position-relative mb-3">
                                            <input type="text" class="form-control" id="example" aria-describedby="number" value={multiplebets} onChange={HandleOnChange} />
                                            <p className="mb-0 nochange1">{multiplebets}</p>
                                            <Slider
                                                min={0}
                                                max={100}
                                                value={multiplebets}
                                                onChange={handleOnChange}
                                                tooltip={false}
                                            />
                                        </div>
                                        <span className="text-danger backred">{error}</span>
                                        {pathName == "Plinko" && <label className="mb-2 fw-600 whtclr">Plinko Bets
                                            <OverlayTrigger
                                                className="toltip_style"
                                                key="top"
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-top`}>
                                                        Set the number of bets you want to wager in a single transaction
                                                    </Tooltip>
                                                }
                                            >
                                                <Button className="bet_overlay_icon"><BsQuestionCircle className="que" /></Button>
                                            </OverlayTrigger>
                                            {/* <BsQuestionCircle className="que" /> */}
                                        </label>}
                                        {pathName == "Plinko" && <div class="position-relative mb-3">
                                            <input type="text" class="form-control" id="example" aria-describedby="number" value={plinkobets} onChange={HandleOnChange3} />
                                            <p className="mb-0 nochange1">{plinkobets}</p>
                                            <Slider
                                                min={6}
                                                max={12}
                                                value={plinkobets}
                                                onChange={handleOnChange3}
                                                tooltip={false}
                                            />
                                        </div>}
                                        {pathName == "Plinko" && <span className="text-danger backred">{error1}</span>}
                                        <div className="row pt-1">
                                            <div class="col-sm-6 col-md-6 mt-3">
                                                <label class="form-label fw-600 whtclr">Max Payout</label>
                                                <div className="borders borders_full_hei_show d-flex justify-content-between">
                                                    {/* ((multiplebets * wager) * 100).toFixed(4) */}
                                                    <p className="mb-0">{maxpayout.toFixed(5) > 0 ? maxpayout.toFixed(5) : maxpayout.toFixed(6) > 0 ? maxpayout.toFixed(6) : maxpayout.toFixed(7) > 0 ? maxpayout.toFixed(7) : maxpayout.toFixed(8) > 0 ? maxpayout.toFixed(8) : maxpayout.toFixed(9) > 0 ? maxpayout.toFixed(9) : maxpayout.toFixed(10) > 0 ? maxpayout.toFixed(10) : maxpayout.toFixed(11)}</p>  <img src={tablecoin} alt="images" />
                                                </div>
                                            </div>
                                            <div class="col-sm-6 col-md-6 mt-3">
                                                <label class="form-label fw-600 whtclr">Total Wager</label>
                                                <div className="borders borders_full_hei_show d-flex justify-content-between">
                                                    {console.log('accountInfo != null && accountInfo != ""', accountInfo != null && accountInfo != "")}
                                                    <p className="mb-0">{(accountInfo != null && accountInfo != "") ? `${(wagerclaimfee).toFixed(6) > 0 ? (wagerclaimfee).toFixed(6) : (wagerclaimfee).toFixed(8) > 0 ? (wagerclaimfee).toFixed(8) : (wagerclaimfee).toFixed(9) > 0 ? (wagerclaimfee).toFixed(9) : (wagerclaimfee).toFixed(10) > 0 ? (wagerclaimfee).toFixed(10) : (wagerclaimfee).toFixed(11) > 0 ? (wagerclaimfee).toFixed(11) : (wagerclaimfee).toFixed(12)}` : 0}</p>  <img src={tablecoin} alt="images" />
                                                </div>
                                            </div>
                                        </div>

                                        {pathName != "Moon" && <details>
                                            <summary class="form-label mt-3 fw-600 whtclr">Advanced</summary>
                                            <p>
                                                <div className="row pt-0">
                                                    <div class="col-sm-6 col-md-6 mt-3">
                                                        <label className="mb-2 fw-600">Stop Gain
                                                            <OverlayTrigger
                                                                className="toltip_style"
                                                                key="top"
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id={`tooltip-top`}>
                                                                        Set your stop gain for multiple bets to cancel the remaining bets if reached
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <Button className="bet_overlay_icon"><BsQuestionCircle className="que" /></Button>
                                                            </OverlayTrigger>
                                                            {/* <BsQuestionCircle className="que" /> */}
                                                        </label>
                                                        {/* if ((e.target.value == '') || regex.test(e.target.value))  value={stopgain} onChange={(e) => {  handleChange3(e)  }} */}
                                                        <input type="text" className="form-control" name="stopgain" readOnly={bet} value={stopgain} onChange={(e) => { if ((e.target.value == '') || regex.test(e.target.value)) { handleChange3(e) } }} />
                                                    </div>
                                                    <div class="col-sm-6 col-md-6 mt-3">
                                                        <label className="mb-2 fw-600">Stop Loss
                                                            <OverlayTrigger
                                                                className="toltip_style"
                                                                key="top"
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id={`tooltip-top`}>
                                                                        Set your stop loss for multiple bets to cancel the remaining bets if reached
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <Button className="bet_overlay_icon"><BsQuestionCircle className="que" /></Button>
                                                            </OverlayTrigger>
                                                            {/* <BsQuestionCircle className="que" /> */}
                                                        </label>

                                                        <input type="text" className="form-control" name="stoploss" readOnly={bet} value={stoploss} onChange={(e) => { if ((e.target.value == '') || regex.test(e.target.value)) { handleChange3(e) } }} />
                                                    </div>
                                                </div>

                                            </p>
                                        </details>}

                                        {(accountInfo == "" || accountInfo == null) ?
                                            <button type="button" class={pathName == "Moon" ? "btn w-100 btn-success earnbtn walletss bluebtn mt-4" : "btn w-100 btn-success earnbtn walletss bluebtn mt-2"} onClick={() => setWalletmodal(true)}
                                            // disabled
                                            >CONNECT WALLET</button> :
                                            <>{isApproved ?//depositTokens(); 
                                                <button type="button" class="btn w-100 btn-success earnbtn walletss bluebtn mt-4" disabled={bet} onClick={async () => { setpendingTx(true); await Bet() }}> BET </button> :
                                                <button type="button" class="btn w-100 btn-success earnbtn walletss bluebtn mt-2" onClick={() => approve()}>APPROVE AND BET </button>
                                            }</>
                                        }

                                    </div>
                                </div>
                            </div>
                            {/* problem */}
                            <div className="bottomborder">
                                <div className="row align-items-center justify-content-start mx-2">
                                    {console.log("dafasdfasdfasd", gameInfo, gameInfo?.GamePlay?.length)}
                                    <div class="col-md-9 col-sm-7 mt-3 mt-sm-0">
                                        <p className="mb-0 mt-4 fw-600 dicename" style={{ color: "white" }}><svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="2" y="2" width="20" height="20" rx="2" stroke="#fff" stroke-width="2"></rect><circle cx="8" cy="7.828" r="2" transform="rotate(45 8 7.828)" fill="#fff"></circle><circle cx="12.243" cy="12.071" r="2" transform="rotate(45 12.243 12.071)" fill="#fff"></circle><circle cx="16.485" cy="16.314" r="2" transform="rotate(45 16.485 16.314)" fill="#fff"></circle></svg> {pathName} <span className="ms-2 f-14 play" onClick={() => setdicemodal(true)}>{gameInfo && gameInfo?.GamePlay?.length > 0 && gameInfo?.Title}</span></p>
                                    </div>
                                    {/* <div class="col-md-3 col-sm-5 mt-3 mt-sm-0">
                                    <div className="row align-items-center">
                                        <div class="col-lg-2 col-md-4 col-4 text-end"><img className="img-fluid volumeimg" src={volumeimg} alt="contest" /></div>
                                        <div class="col-lg-10 col-md-8 col-8 px-0">
                                            <Slider
                                                min={0}
                                                max={100}
                                                value={volumeup}
                                                onChange={handleOnChange2}
                                            /></div>
                                    </div>
                                </div> */}
                                </div>
                            </div>

                            <div className=" gamecardsec cardss pt-3 pb-4">
                                <div class="row justify-content-start">
                                    {gameCard && gameCard?.length > 0 && gameCard?.map((e, i) => {
                                        { console.log("eeeeeeeeee_gamedetails", e, e?.GameName, e?.Image) }
                                        if (e?.GameName != pathName && e?.Status == "Active") {
                                            return (<div class="col-lg-3 col-sm-6 col-md-4 mt-3">
                                                <Link onClick={() => window.location.href = `${FRONTENDURL}games/${e?.GameName}`} ></Link>
                                                <Link onClick={() => window.location.href = `${FRONTENDURL}games/${e?.GameName}`} >
                                                    <div class="gamecard card" onMouseEnter={()=>setCardhover(i)} onMouseLeave={()=>setCardhover()}>
                                                        <div className="card-img">
                                                            <img className="img-fluid gameimg" src={`${BACKURL}/Allgames/${e?.Image}`} alt="contest" />
                                                        </div>
                                                        <div class="card-body">
                                                            <h6 class="card-title mb-0">{e?.GameName}</h6>
                                                        </div>
                                                        <div className={`${cardhover === i ? "cardhoverlayer active" : "cardhoverlayer"}`}>
                                                        {cardhover === i &&   <img src={splashimg} className="img-fluid" alt="image" />}
                                                      
                                                       <img src={playbtn} className="playbtn" onClick={() => window.location.href = `${FRONTENDURL}games/${e?.GameName}`}/>
                                                    </div>
                                                    </div>
                                                </Link>
                                            </div>)
                                        }
                                    }
                                    )}
                                </div>
                            </div>

                            <div className="pt-3 pb-4">

                                {/* {(check) && <Gametable />} */}
                                <Gametable setTransFilter={(data) => { setTransFilter(data) }} transdetails={gametrans} transfilter={transfilter} />
                            </div>


                        </div>

                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}      