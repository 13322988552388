import React, { useEffect, useState } from "react";

import { NavLink, Link } from "react-router-dom";

import gaming from "../assets/gamestrophy.png";
import gamingactive from "../assets/gamingactive.png";
import earnimg from "../assets/earnimgs.png";
import earnactive from "../assets/rocketss.png";
import refimg from "../assets/ref.png";
import refactive from "../assets/refactive.png";

import exchange from "../assets/exchange.png";

import ranges from "../assets/sidemenu/range.png";
import coinflip from "../assets/sidemenu/coinflip.png";
import wheel from "../assets/sidemenu/wheel.png";
import plinko from "../assets/sidemenu/plinko.png";
import moon from "../assets/sidemenu/moon.png";
import rpc from "../assets/sidemenu/rps.png";
import limbo from "../assets/sidemenu/limbo.png";
import dice from "../assets/sidemenu/dice.png";
import logoimg from "../assets/logoimg.png";
import { getAllGamesInfoHooks } from "../hooks/UseUser";
import { Accordion } from "react-bootstrap";


export default function Sidebar(props) {
    const [showDropdown, setShowDropdown] = useState(false);
    const [gamedetails, setGameDetails] = useState([]);

    const [menushow, setMenushow] = useState(false);

    console.log("gamedetails_gamedetails", gamedetails)
    useEffect(() => {
        async function fetchData() {
            let res = await getAllGamesInfoHooks();
            console.log("respondgamedata", res?.data)
            if (res?.data?.status == true) {
                setGameDetails(res?.data?.data)
            }
        }
        fetchData()
    }, [])

    return (
        <>
            <div>

                <div className="leftsidebar">
                    {/* <p className="whtclr mb-2" >Menu</p> */}
                    {/* <Link className="logos d-none d-xl-block" to="/">
                        <img src={logoimg} className="sidelogo mb-2" alt="image" />
                    </Link> */}
                    <div className="mx-0 mx-xl-2">
                        <Accordion className="">
                            <Accordion.Item >
                                <Accordion.Header className="sideheadlabel"> 
                                            <div >
                                    <img className="mb-0 normalimg" src={gaming} alt="gameimg" />
                                    <span className="ms-2">Games</span></div></Accordion.Header>
                                {console.log("gamedetailsgamedetailsgamedetails", gamedetails)}
                                <Accordion.Body className="pt-0 px-0 pb-2 mt-2">
                                    {gamedetails && gamedetails?.length > 0 && <>
                                        {console.log("gamedetails", gamedetails)}
                                        {
                                            (gamedetails[7]["GameName"] == "Dice" && gamedetails[7]["Status"] == "Active") &&
                                            <NavLink activeClassName={"active"} to="/games/Dice" onClick={() => {
                                                if (props.handleClose) {
                                                    props.handleClose()
                                                }
                                            }} >
                                                <img className="mb-0 dropsideimg" src={dice} alt="gameimg" /> Dice</NavLink>}
                                        {(gamedetails[6]["GameName"] == "Range" && gamedetails[6]["Status"] == "Active") && <NavLink activeClassName={"active"} to="/games/Range" onClick={() => {
                                            if (props.handleClose) {
                                                props.handleClose()
                                            }
                                        }}>
                                            <img className="mb-0 dropsideimg" src={ranges} alt="gameimg" />

                                            Range</NavLink>
                                        }
                                        {(gamedetails[5]["GameName"] == "CoinFlip" && gamedetails[5]["Status"] == "Active") && <NavLink activeClassName={"active"} to="/games/CoinFlip" onClick={() => {
                                            if (props.handleClose) {
                                                props.handleClose()
                                            }
                                        }}>
                                            <img className="mb-0 dropsideimg" src={coinflip} alt="gameimg" /> Coin Flip</NavLink>}
                                        {(gamedetails[4]["GameName"] == "Wheel" && gamedetails[4]["Status"] == "Active") && <NavLink activeClassName={"active"} to="/games/Wheel" onClick={() => {
                                            if (props.handleClose) {
                                                props.handleClose()
                                            }
                                        }}>
                                            <img className="mb-0 dropsideimg" src={wheel} alt="gameimg" /> Wheel</NavLink>}
                                        {(gamedetails[3]["GameName"] == "Plinko" && gamedetails[3]["Status"] == "Active") && <NavLink activeClassName={"active"} to="/games/Plinko" onClick={() => {
                                            if (props.handleClose) {
                                                props.handleClose()
                                            }
                                        }}>
                                            <img className="mb-0 dropsideimg" src={plinko} alt="gameimg" /> Plinko</NavLink>}
                                        {(gamedetails[2]["GameName"] == "Moon" && gamedetails[2]["Status"] == "Active") && <NavLink activeClassName={"active"} to="/games/Moon" onClick={() => {
                                            if (props.handleClose) {
                                                props.handleClose()
                                            }
                                        }}>
                                            <img className="mb-0 dropsideimg" src={moon} alt="gameimg" /> Moon</NavLink>}
                                        {(gamedetails[1]["GameName"] == "Limbo" && gamedetails[1]["Status"] == "Active") && <NavLink activeClassName={"active"} to="/games/Limbo" onClick={() => {
                                            if (props.handleClose) {
                                                props.handleClose()
                                            }
                                        }}>
                                            <img className="mb-0 dropsideimg" src={limbo} alt="gameimg" /> Limbo</NavLink>}
                                        {(gamedetails[0]["GameName"] == "RPC" && gamedetails[0]["Status"] == "Active") && <NavLink activeClassName={"active"} to="/games/RPC" onClick={() => {
                                            if (props.handleClose) {
                                                props.handleClose()
                                            }
                                        }}>
                                            <img className="mb-0 dropsideimg" src={rpc} alt="gameimg" /> RPC</NavLink>}
                                    </>}

                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        {/* <div className="a_img">
                            <NavLink activeClassName={"active"} to="/earn" className="bbs">
                                <img className="mb-2 normalimg" src={exchange} alt="gameimg" />
                                <img className="mb-2 hoverimg" src={exchange} alt="gameimg" />
                                <span className="ms-2">Exchange</span></NavLink>
                            <NavLink activeClassName={"active"} to="/stake" className="bbs">
                                <img className="mb-2 normalimg" src={earnimg} alt="gameimg" />
                                <img className="mb-2 hoverimg" src={earnimg} alt="gameimg" />
                                <span className="ms-2">Stake</span></NavLink>
                            <NavLink activeClassName={"active"} to="/referral" className="">
                                <img className="mb-2 normalimg" src={refimg} alt="gameimg" />
                                <img className="mb-2 hoverimg" src={refimg} alt="gameimg" />
                                <span className="ms-2">Referral</span></NavLink>
                        </div> */}
                    </div>
                </div>



            </div >
        </>
    );
}


