import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import Copimg from '../assets/copyimg.svg';
import Logo from '../assets/logominis.png';
import walletimg from '../assets/sidemenu/wallet.png';
import { NavLink, Link } from "react-router-dom";
import { Dropdown } from 'react-bootstrap';
import Wallets from '../Components/Modals/Wallet';
import Select from 'react-select';
import tablecoin from '../assets/tablecoin.png';
import menuburger from '../assets/menuburger.png';
import goldcoin from '../assets/goldicon.png';
import Offcanvas from 'react-bootstrap/Offcanvas';
import contest from "../assets/cup.png";
import stakes from "../assets/stakess.png";
import rocket from "../assets/rocket.png";
import {getChainId} from "../hooks/useAccount"
import {CHAINS} from "../config/env"
import CopyToClipboard from "react-copy-to-clipboard";
import { copyUrl } from "../hooks/UseUser";
import { MdOutlineContentCopy } from "react-icons/md";
import Sidebar from "./sidebar1";
import logoimg from "../assets/logoimg.png";


export default function Header() {
    const [accountInfo, setAccountInfo] = useState("");

    console.log("accountInfo_accountInfo_V",accountInfo);
    const [currentChain,setcurrentChain]=useState(0)
    const [wallet, setShowWallet] = useState(false);

    const [walletmodal, setWalletmodal] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
console.log("walletmodaldata", walletmodal)
    const chainId = async ()=>{
        let chaindd = getChainId()
         console.log("chaindd_chainddchaindd", chaindd.at,CHAINS[currentChain]);
      //  let chainid =chaindd.CHAIN_ID
    //   console.log("chainid_chainid_chainidv",chainid);
        setcurrentChain(chaindd)

    }

    useEffect(() => {
        chainId()
       //  const index = localStorage.getItem("CHAIN");
       //  console.log("index___indexindex",index);
       //  setcurrentChain(index)
        
       const walletAddress = sessionStorage.accountInfo ? setAccountInfo(sessionStorage.getItem("accountInfo")) : ""
   }, [accountInfo]);

   const logOut = async () => {
    console.log("gameheader logout call")
    setAccountInfo("");
  
    localStorage.removeItem('accessToken');
    sessionStorage.removeItem('accountInfo');
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/"
}
    return (
        <>
            <div className="gameheader">
                <div className="customcontainer container">
                {walletmodal && <Wallets onDismiss={() => setWalletmodal(false)} />}
                <div class="header_alert__8Mnqu">
                    <div className="d-flex justify-content-between align-items-center">
                        <a href="https://blueiceegiant.maticz.in/"> <img src={Logo} alt="images headlogo" className="headlogo" /></a>

                        <Nav className={`ms-xl-auto me-xl-auto align-items-xl-center flex-row gap-4 d-none d-xl-flex navbar`}>
                                <NavLink to="/">Home </NavLink>
                                <NavLink to="https://honeybluedex.maticz.in/swap">HBLU</NavLink>
                                <NavLink to="https://honeybluelockedstaging.maticz.in/staking">Locked staking</NavLink>
                                <NavLink className="active" to={`#`}>Game</NavLink>
                                <NavLink to="">Faq</NavLink>



                            </Nav>
                            <div className="d-flex justify-content-between justify-content-xl-end align-items-center gap-3">
                        {/* <div className="d-flex"> */}
                            {/* <h6 className="fw-600 smart mb-0">Volumes <span className="lgray smart"> $ 15,23,231</span></h6>
                            <h6 className="fw-600 smart mb-0 ms-3">Bets <span className="lgray smart"> $ 15,23,231</span></h6> */}
                        {/* </div> */}

                      

                      
                        <div>
                        {
                                    (accountInfo == "" || accountInfo == null) ?
                                        <button class="btn btn-success earnbtn mb-0 fw-600 d-none d-lg-block" onClick={() => setWalletmodal(true)}>  <img className="wimg" src={walletimg} alt="images" /> Connect Wallet</button> :
                                        <div className="ms-3 walletdrop">
                                        <Dropdown onMouseLeave={() => setShowDropdown(false)}
                                            onMouseOver={() => setShowDropdown(true)}>
                                            <Dropdown.Toggle className="ptoken btn btn-success earnbtn" id="dropdown-basic">
             
                                                <img className="wimg" src={walletimg} alt="images" /> {`${accountInfo.substring(0, 5)}...${accountInfo.substring(38, 42)}`}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu show={showDropdown} className="right_menu_custom">
                                                <div className="d-flex justify-content-between">
                                                    <p className="f-13 mb-0">{`${accountInfo.substring(0, 5)}...${accountInfo.substring(38, 42)}`}</p>
                                                    <CopyToClipboard text={`${accountInfo}`} onCopy={() => { copyUrl('Copied WalletAddress', `${accountInfo}`) }}>
                                                        <MdOutlineContentCopy className="copysvg" />
                                                    </CopyToClipboard></div>
                                                <div className="mt-3">
                                                    <Link className="mt-2 mb-0" onClick={()=>logOut()} >Disconnect</Link>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                }
                        </div>
                        <div className="mobileview">
                            <img onClick={handleShow} className="mimg" src={menuburger} alt="menu" />
                        </div>

                    </div>
               
                    </div>




                    <Offcanvas show={show} onHide={handleClose} placement="end">
                        <Offcanvas.Header closeButton className="justify-content-end">
                            {/* <Offcanvas.Title className="fw-700 whtclr"><Link to="/"><img src={logoimg} className="sidelogo mb-0" alt="image" /></Link></Offcanvas.Title> */}
                        </Offcanvas.Header>
                        <Offcanvas.Body className="pt-0 mt-3">

                       



<Nav className={` navbar justify-content-start d-flex flex-column gap-3 align-items-start mt-3 mb-2`}>
                                <NavLink to="/">Home </NavLink>
                                <NavLink to="https://honeybluedex.maticz.in/swap">HBLU</NavLink>
                                <NavLink to="https://honeybluelockedstaging.maticz.in/staking">Locked staking</NavLink>
                                <NavLink className="active" to={`https://honeyblue.maticz.in/games`}>Game</NavLink>
                                <NavLink to="">Faq</NavLink>



                            </Nav>
                            {
                                    (accountInfo == "" || accountInfo == null) ?
                                        <button class="btn btn-success earnbtn mb-0 fw-600" onClick={() => setWalletmodal(true)}>  <img className="wimg" src={walletimg} alt="images" /> Connect Wallet</button> :
                                        <div className="ms-3 walletdrop">
                                        <Dropdown onMouseLeave={() => setShowDropdown(false)}
                                            onMouseOver={() => setShowDropdown(true)}>
                                            <Dropdown.Toggle className="ptoken btn btn-success earnbtn" id="dropdown-basic">
             
                                                <img className="wimg" src={walletimg} alt="images" /> {`${accountInfo.substring(0, 5)}...${accountInfo.substring(38, 42)}`}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu show={showDropdown} className="right_menu_custom">
                                                <div className="d-flex justify-content-between">
                                                    <p className="f-13 mb-0">{`${accountInfo.substring(0, 5)}...${accountInfo.substring(38, 42)}`}</p>
                                                    <CopyToClipboard text={`${accountInfo}`} onCopy={() => { copyUrl('Copied WalletAddress', `${accountInfo}`) }}>
                                                        <MdOutlineContentCopy className="copysvg" />
                                                    </CopyToClipboard></div>
                                                <div className="mt-3">
                                                    <Link className="mt-2 mb-0" onClick={()=>logOut()} >Disconnect</Link>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                }

                            <div className="mt-3 allcontest">
                                {/* <button type="button" class="btn btn-success contest mt-3 mx-0"><img src={contest} alt="contest" /> CONTEST</button> */}
                                {/* <Link to="/stake"><button type="button" class="btn btn-success stakebtn  mt-3 ms-0"><img src={stakes} alt="contest" /> STAKE</button></Link> */}
                                {/* <Link to="/earn"><button type="button" class="btn btn-success earnbtn  mt-3  ms-3"><img src={rocket} alt="contest" /> EARN</button></Link> */}
                            </div>
                            
                            <div className="gameheader_sidebar">
                              <Sidebar handleClose={handleClose}/>
                            </div>
                           
                        </Offcanvas.Body>
                    </Offcanvas>


                </div>
                </div>
            </div>
        </>
    );
}